import React, { ForwardedRef, forwardRef, Fragment } from 'react';
import Modal from '../../modal/Modal';
import Button from '../../buttons/button/Button';
import { ModalConfirmDeclineStyles } from './ModalConfirmDeclineStyles';
import { ButtonProps } from '../../buttons/button/types';

interface ModalConfirmDeclineProps {
  title: string;
  description?: string;

  onClose(): void;

  buttons: ButtonProps[];
  error?: string;
  extraClasses?: string;
  isLoading?: boolean;
}

const ModalConfirmDecline = forwardRef(({
  title, description, buttons, onClose, error, extraClasses, isLoading,
}: ModalConfirmDeclineProps, ref: ForwardedRef<HTMLDivElement>) => (
  <ModalConfirmDeclineStyles ref={ref}>
    <Modal className={extraClasses} onClose={() => (isLoading ? null : onClose)}>
      <div className="modalHead">
        <span className="textBold28 title">{title}</span>

        {description && <span className="textBase18">{description}</span>}
      </div>

      <div className="modalButtons">
        {buttons.map((button, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={index}>
            <Button
              onClick={button.onClick}
              text={button.text}
              theme={button.theme}
              disabled={button.disabled}
              type={button.type}
              Icon={button.Icon}
              isLoading={isLoading || button.isLoading}
            />
          </Fragment>
        ))}
      </div>

      {error && <span className="textBase16 errorText">{error}</span>}
    </Modal>
  </ModalConfirmDeclineStyles>
));

export default ModalConfirmDecline;
