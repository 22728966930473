// import React from 'react';
// import { ButtonStyles } from './ButtonStyles';
// import { ButtonProps } from './types';
//
// function Button({
//   onClick, text, type = 'button', theme = 'filled', Icon, iconPosition = 'left', error, size = 'big', id, disabled,
// }: ButtonProps) {
//   return (
//     <ButtonStyles
//       id={id}
//       size={size}
//       className="button"
//       theme={theme}
//       iconPosition={iconPosition}
//       type={type}
//       onClick={onClick}
//       error={error || false}
//       disabled={disabled}
//     >
//       {Icon && <Icon />}
//       <span className="textSemiBold16">{text}</span>
//     </ButtonStyles>
//   );
// }
//
// export default Button;

import React, { CSSProperties, JSX } from 'react';
import { ButtonStyles } from './ButtonStyles';
import MoonLoader from 'react-spinners/MoonLoader';
import classNames from 'classnames';

export interface ButtonProps {
  onClick(): void;
  text?: string;
  Icon?: JSX.Element;
  theme?: 'red' | 'black';
  fullWidth?: boolean;
  type?: 'button' | 'submit';
  isLoading?: boolean;
  disabled?: boolean;
  extraButtonStyles?: CSSProperties;
}

function Button({
  onClick, text, fullWidth, type = 'button', theme = 'red', isLoading = false, disabled = false, extraButtonStyles, Icon,
}: ButtonProps) {
  return (
    <ButtonStyles type={type} fullWidth={fullWidth} onClick={onClick} className={classNames(`button textBase16 ${theme}`, { isLoading })} disabled={disabled || isLoading} style={extraButtonStyles}>
      {isLoading && <MoonLoader color={theme === 'red' ? '#FFF' : '#A21919'} size={16} />}

      {text && (
        <span className="text">
          {text}
        </span>
      )}

      {Icon && (
        Icon
      )}

      {isLoading && <MoonLoader color={theme === 'red' ? '#FFF' : '#A21919'} size={16} />}
    </ButtonStyles>
  );
}

export default Button;
