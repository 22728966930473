export const navLinks = [
  {
    name: 'Моя страница',
    path: '/',
  },
  {
    name: 'Лента новостей',
    path: '/news',
  },
  // {
  //   name: 'Объявления',
  //   path: '/announces',
  // },
  {
    name: 'Коннекты',
    path: '/connects',
  },
  {
    name: 'Сообщения',
    path: '/messages',
  },
  {
    name: 'Магазины',
    path: '/shops',
  },
  {
    name: 'Библиотека',
    path: '/library',
  },
];
