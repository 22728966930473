import React from 'react';

function Pack5() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32"
    >
      <g clipPath="url(#clip0_37_2064)">
        <path
          fill="#000"
          d="M16.5 0C7.663 0 .5 7.164.5 16s7.163 16 16 16c8.836 0 16-7.164 16-16 0-8.837-7.164-16-16-16zM4.845 22.026c0-4.998 4.39-4.997 5.364-6.304l.11-.596c-1.368-.694-2.334-2.365-2.334-4.321 0-2.577 1.676-4.666 3.743-4.666 1.628 0 3.009 1.298 3.524 3.106-2.07.658-3.602 2.903-3.602 5.572 0 1.837.724 3.526 1.913 4.6-.19.097-.403.197-.586.283-.958.45-2.287 1.075-3.246 2.326H4.845zm11.703 4.012H9.616c0-4.998 4.39-4.997 5.364-6.304l.112-.596c-1.369-.694-2.335-2.365-2.335-4.321 0-2.577 1.676-4.666 3.743-4.666s3.743 2.09 3.743 4.666c0 1.939-.95 3.6-2.3 4.305l.127.678c1.07 1.243 5.313 1.324 5.313 6.238h-6.835zm6.72-4.011c-.959-1.252-2.288-1.877-3.247-2.327-.188-.088-.398-.187-.585-.283 1.188-1.074 1.913-2.767 1.913-4.6 0-2.668-1.53-4.913-3.6-5.571.515-1.809 1.896-3.106 3.524-3.106 2.067 0 3.742 2.089 3.742 4.665 0 1.94-.948 3.6-2.299 4.305l.127.678c1.069 1.243 5.313 1.324 5.313 6.239h-4.888z"
        />
      </g>
      <defs>
        <clipPath id="clip0_37_2064">
          <path fill="#fff" d="M0 0H32V32H0z" transform="translate(.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Pack5;
