import React, { useRef, useState } from 'react';
import SidebarNav from '../main/sidebarNav/SidebarNav';
import AdvertisingIcon from '../../assets/icons/main/AdvertisingIcon';
import SettingsIcon from '../../assets/icons/main/SettingsIcon';
import ExitIcon from '../../assets/icons/main/ExitIcon';
import { Link } from 'react-router-dom';
import Pack1 from '../../assets/icons/main/stickerpacks/Pack1';
import Pack2 from '../../assets/icons/main/stickerpacks/Pack2';
import Pack3 from '../../assets/icons/main/stickerpacks/Pack3';
import Pack4 from '../../assets/icons/main/stickerpacks/Pack4';
import Pack5 from '../../assets/icons/main/stickerpacks/Pack5';
import Pack6 from '../../assets/icons/main/stickerpacks/Pack6';
import Pack7 from '../../assets/icons/main/stickerpacks/Pack7';
import Pack8 from '../../assets/icons/main/stickerpacks/Pack8';
import { changeUser } from '../../state/slices/userSlice';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { Api } from '../../api';
import LogoRow from '../../assets/icons/LogoRow';
import { useWindowSize } from 'usehooks-ts';
import OpenMenuButton from '../../assets/icons/main/OpenMenuButton';

import img1 from '../../assets/images/main/rutor_lux2.gif';
import MenuMobile from '../main/menuMobile/MenuMobile';
import { MainLayoutStyles } from './MainLayoutStyles';

const stickerPacks = [
  <Pack1 />, <Pack2 />, <Pack3 />, <Pack4 />, <Pack5 />, <Pack6 />, <Pack7 />, <Pack8 />,
];

function MainLayout({ children }: {children: React.ReactNode}) {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const reduxDispatch = useAppDispatch();
  const { data: user } = useAppSelector((state) => state.user);

  const windowSize = useWindowSize();
  const headRef = useRef<HTMLDivElement>(null);
  const pageRef = useRef<HTMLDivElement>(null);

  const logout = () => {
    Api.logout().then();

    if (localStorage.getItem('chat_token')) {
      localStorage.removeItem('chat_token');
    }

    localStorage.removeItem('logged_in');
    localStorage.removeItem('access_token');
    localStorage.removeItem('access_token_expired_at');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('refresh_token_expired_at');
    reduxDispatch(changeUser(null));
  };

  const getCenterHeight = () => {
    if (headRef.current && pageRef.current) {
      if (pageRef.current.offsetHeight > headRef.current.offsetHeight + 42 + 20) {
        return pageRef.current.offsetHeight;
      }
      return pageRef.current.offsetHeight - headRef.current.offsetHeight + 42 + 20;
    }
  };

  return (
    <MainLayoutStyles id="mainLayout" ref={pageRef} headHeight={headRef?.current?.offsetHeight} className="container">
      <div className="head" ref={headRef}>
        <div className="logoContainer">
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <button className="menuButton" type="button" onClick={() => setMenuOpen(true)}>
            <OpenMenuButton />
          </button>

          <Link to="/main" className="logo">
            <LogoRow />
          </Link>
        </div>

        <div className="userInfoContainer">
          <div className="userInfo userInfoDesktop">
            {user && <span>{user.login}</span>}

            <span>1000</span>
          </div>

          <div className="balance balanceTablet textBase16">
            Баланс 0
          </div>
        </div>

        <div className="buttonsWrapper">
          <div className="buttonsContainer textBase16">
            <Link to="/profile" type="button" className="layoutButton">
              <span>Настройки</span>

              <SettingsIcon />
            </Link>

            <button type="button" onClick={() => logout()} className="layoutButton">
              <span>Выход</span>

              <ExitIcon />
            </button>
          </div>

          <div className="balance balanceDesktop textBase16">
            Баланс 0
          </div>
        </div>
      </div>

      <SidebarNav />

      <MenuMobile
        open={menuOpen}
        setOpen={() => setMenuOpen(false)}
      />

      <div className="adverstingContainer1">
        <div className="advertising">
          <AdvertisingIcon />
          <span>Здесь может быть ваша реклама</span>
        </div>
      </div>

      <div className="adverstingContainer2">
        <div className="advertising">
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <a className="advertisingItem" target="_blank" href="http://rutorsite3s7oalfxlcv5kdk6opadvkoremcoyrdm75rgips6pv33did.onion" rel="noreferrer">
            <img src={img1} alt="" />
          </a>

          {new Array(4).fill(1).map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className="emptyAdvertisingItem">

              <AdvertisingIcon width={86} height={72} />
              <span className="textBase16">Здесь может быть ваша реклама</span>
            </div>
          ))}
        </div>
      </div>

      <div className="center">
        {children}
      </div>
    </MainLayoutStyles>
  );
}

export default MainLayout;
