import React from 'react';

function ExitIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#CB1F1F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 14H3.333A1.334 1.334 0 012 12.667V3.333A1.333 1.333 0 013.333 2H6M10.667 11.333L14 8l-3.333-3.333M14 8H6"
      />
    </svg>
  );
}

export default ExitIcon;
