import styled from 'styled-components';

export const NewsFeedStyles = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  #mainLayout {
    .center {
      grid-column: 4/10;
      grid-row: 1/5;

      display: flex;
      flex-direction: column;

      max-height: 100vh;

      @media (max-width: 1000px) {
        grid-column: 1/3;
        grid-row: 2/3;
      }

      @media (max-width: 500px) {
        grid-column: 1/2;
      }
    }
  }

  
  .userInfoContainer {
    display: none !important;
  }
`;
