import React from 'react';

function Pack3() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32"
    >
      <g fill="#000" clipPath="url(#clip0_37_2045)">
        <path d="M24.154 9.537H8.846a.277.277 0 00-.277.277V20.05a.277.277 0 00.277.277h15.308a.277.277 0 00.276-.277V9.814a.276.276 0 00-.276-.277z" />
        <path d="M16.5 0C7.663 0 .5 7.164.5 16c0 8.835 7.163 16 16 16 8.836 0 16-7.164 16-16 0-8.837-7.164-16-16-16zm9.867 20.05c0 1.222-.99 2.213-2.213 2.213h-5.029V23.5h2.539a.83.83 0 010 1.66H11.336a.83.83 0 010-1.66h2.539v-1.236h-5.03a2.213 2.213 0 01-2.212-2.213V9.814c0-1.222.99-2.213 2.213-2.213h15.308c1.222 0 2.213.99 2.213 2.213V20.05z" />
      </g>
      <defs>
        <clipPath id="clip0_37_2045">
          <path fill="#fff" d="M0 0H32V32H0z" transform="translate(.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Pack3;
