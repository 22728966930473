import styled from 'styled-components';
import colors from '../../../constants/colors';

interface ButtonStylesProps {
  fullWidth?: boolean;
}

export const ButtonStyles = styled.button<ButtonStylesProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  
  padding: 12px 16px;
  
  border-radius: 4px;
  
  &.isLoading {
    cursor: wait;
  }
  
  &:not(.isLoading) {
    &:disabled {
      opacity: 0.35;

      cursor: not-allowed;
    }
  }
  
  &.red {
    box-shadow: 0px 2px 3px 1px rgba(213, 76, 76, 0.30), 0px 0px 2px 0px rgba(213, 76, 76, 0.40);
    background: ${colors.main2};
    color: ${colors.secondary1};

    &:hover {
      background: ${colors.main3};
      box-shadow: 0px 3px 6px 2px rgba(213, 76, 76, 0.40), 0px 1px 2px 0px rgba(213, 76, 76, 0.50);
    }

    &:focus {
      outline: 2px solid ${colors.main1};
    }
  }

  &.black {
    border: 1px solid var(--red-2, #CB1F1F);
    background: var(--background, #0F0E0E);
    box-shadow: 0px 2px 3px 1px rgba(213, 76, 76, 0.30), 0px 0px 2px 0px rgba(213, 76, 76, 0.40);
    
    .text {
      color: var(--red-2, #CB1F1F);
    }
    
    &:hover {
      border: 1px solid var(--red-3, #A21919);
      background: var(--background, #0F0E0E);
      box-shadow: 0px 3px 6px 2px rgba(213, 76, 76, 0.40), 0px 1px 2px 0px rgba(213, 76, 76, 0.50);
    }

    &:focus {
      outline: 2px solid ${colors.main1};
    }
  }
  
  transition: 0.3s ease all;
  
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
`;

// import styled from 'styled-components';
// import colors from '../../../constants/colors';
//
// import ButtonProps from './Button';
// import { ButtonStylesProps } from './types';
// import hexToRgb from '../../../utils/hexToRgba';
//
// export const ButtonStyles = styled.button<ButtonStylesProps>`
//   &.button {
//     display: flex;
//     flex-direction: ${(props) => (props.iconPosition === 'right' ? 'row-reverse' : 'row')};
//     justify-content: center;
//     align-items: center;
//     padding: 12px;
//     width: 100%;
//     gap: 10px;
//
//     transition: 0.3s ease all;
//     border-width: 1px;
//     border-radius: 4px;
//     border-style: solid;
//
//     color: ${(props) => (props.theme === 'filled' ? '#FFF' : props.theme === 'secondary' ? colors.secondary5 : colors.main5)};
//     border-color: ${(props) => (props.theme === 'filled' ? colors.main5 : props.theme === 'secondary' ? colors.secondary5 : colors.main5)};
//     background: ${(props) => (props.theme === 'filled' ? colors.main5 : '#FFF')};
//
//     &:hover {
//       color: ${(props) => (props.theme === 'filled' ? '#FFF' : props.theme === 'secondary' ? colors.secondary6 : colors.main6)};
//       border-color: ${(props) => (props.theme === 'filled' ? colors.main6 : props.theme === 'secondary' ? colors.secondary6 : colors.main6)};
//       background: ${(props) => (props.theme === 'filled' ? colors.main6 : '#FFF')};
//     }
//
//     &:focus {
//       outline: 2px solid ${(props) => (props.theme === 'filled' ? colors.main3 : props.theme === 'secondary' ? colors.secondary3 : colors.main3)};
//       border-color:${(props) => (props.theme === 'filled' ? colors.main3 : props.theme === 'secondary' ? colors.secondary3 : colors.main3)};
//       color: ${(props) => (props.theme === 'filled' ? '#FFF' : props.theme === 'secondary' ? colors.secondary5 : colors.main5)};
//       background: ${(props) => (props.theme === 'filled' ? colors.main5 : '#FFF')};
//     }
//
//     ${(props) => {
//     if (props.size === 'semiSmall') {
//       return `
//           padding: 4px 12px;
//         `;
//     }
//   }}
//
//     ${(props) => {
//     if (props.theme === 'transparent' || props.theme === 'filled') {
//       return `
//           box-shadow: 0px 0px 2px rgba(14, 146, 133, 0.4), 0px 2px 3px 1px ${hexToRgb(colors.main5, 0.3)};
//
//           &:hover {
//             box-shadow: 0px 1px 2px rgba(11, 117, 106, 0.5), 0px 3px 6px 2px ${hexToRgb(colors.main6, 0.4)};
//           }
//
//           &:focus {
//             box-shadow: 0px 1px 2px rgba(91, 188, 178, 0.3), 0px 1px 3px 1px ${hexToRgb(colors.main3, 0.15)};
//           }
//         `;
//     }
//   }}
//
//     ${(props) => {
//     if (props.theme === 'transparent') {
//       return `
//           &:hover {
//             svg {
//               path: {
//                 fill: ${colors.main5};
//               };
//             };
//           }
//
//           &:focus {
//             svg {
//               path: {
//                 fill: ${colors.main5};
//               };
//             };
//           }
//         `;
//     }
//   }}
//   }
// `;
