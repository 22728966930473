import styled from 'styled-components';

export const SidebarNavStyles = styled.div`
  display: grid;
  gap: 32px;
  
  padding: 48px;
  
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--red-2, #CB1F1F);
  
  svg {
    width: 100%;
  }
  
  nav {
    display: grid;
    gap: 16px;
    
    a {
      color: #BF2B2B;
      
      transition: 0.3s ease all;
      
      &:hover {
        color: var(--red-4);
      }
    }
  }
`;
