import React from 'react';

function Pack2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32"
    >
      <g fill="#000" clipPath="url(#clip0_37_2056)">
        <path d="M18.148 11.542l-1.044 1.044a.832.832 0 01-.763.224.8.8 0 01-.446-.224l-1.043-1.044a3.573 3.573 0 00-2.533-1.047c-.918 0-1.835.349-2.534 1.047-1.367 1.368-1.354 3.56.03 5.1 1.412 1.569 5.292 4.803 6.685 5.95 1.393-1.147 5.274-4.382 6.686-5.95 1.384-1.54 1.397-3.732.03-5.1a3.588 3.588 0 00-5.068 0z" />
        <path d="M16.5 0C7.663 0 .5 7.164.5 16s7.163 16 16 16c8.836 0 16-7.164 16-16s-7.164-16-16-16zm7.92 17.751c-1.812 2.013-7.153 6.36-7.38 6.544a.798.798 0 01-.54.185h-.018a.826.826 0 01-.523-.185c-.227-.184-5.567-4.531-7.378-6.543-1.985-2.207-1.972-5.381.03-7.384a5.248 5.248 0 017.415 0l.474.475.475-.475a5.25 5.25 0 017.414 0c2.002 2.003 2.015 5.177.03 7.383z" />
      </g>
      <defs>
        <clipPath id="clip0_37_2056">
          <path fill="#fff" d="M0 0H32V32H0z" transform="translate(.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Pack2;
