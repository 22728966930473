import { useEffect } from 'react';

export function useClickOutside(ref: any, callback: () => void, exclude?: any) {
  useEffect(() => {
    function handleClickOutside(event: { target: any; }) {
      if (exclude && Array.isArray(exclude)) {
        if (exclude.some((item) => item && item.current && item.current.contains(event.target))) {
          return;
        }
      } else if (exclude && exclude.current && exclude.current.contains(event.target)) {
        return;
      }

      if (ref && ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, exclude, callback]);
}
