import styled from 'styled-components';

export const CreatePostStyles = styled.div`
  .modalBody {
    padding: 32px 32px 24px 32px;
  }

  .modalBody {
    @media (min-width: 768px) {
      max-width: 630px;
      width: 100%;
    }
  }
  
  .autoResizeTextArea {
    margin-bottom: 12px;

    resize: none;
    background: var(--Cards2, #1C1C1C);
    border: none;
    color: var(--Grey-2, #D9D9D9);
    font-family: Gilroy-Regular;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    
    outline: none;
    
    &::placeholder {
      color: var(--Grey-2, #D9D9D9);

      font-family: Gilroy-Regular;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 34px;
    }
  }
  
  .openButton {
    display: flex;
    align-items: center;
    gap: 12px;
    
    width: 100%;
    padding: 16px;
    box-sizing: border-box;

    border-radius: 4px;
    border: 1px solid var(--Red-2, #CB1F1F);
    background: #1C1C1C;
    color: var(--Grey-2, #D9D9D9);
  }
  
  .postModal {
    min-height: 524px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    color: var(--Grey-2, #D9D9D9);

    @media (min-width: 768px) {
      min-height: 630px;
    }
    
    .imagesContainer {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      
      padding-bottom: 10px;
    }
    
    
    .imagePreview {
      position: relative;
      
      width: fit-content;
      
      .imageButtons {
        position: absolute;
        right: 6px;
        top: 6px;
        
        display: flex;
        gap: 6px;
        
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          
          width: 32px;
          height: 32px;
          border-radius: 100%;
          
          background: rgba(57, 54, 54, 0.80);
        }
      }
      
      img {
        object-fit: contain;
        max-width: 187px;
        max-height: 200px;
        width: auto;
        height: auto;
      }
    }

    .content {
      max-height: 70vh;

      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      overflow: auto;
    }

    .head {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .footer {
      position: relative;

      display: flex;
      justify-content: space-between;
      align-items: center;

      padding-top: 24px;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% + 64px);
        height: 1px;

        background: #393636;
      }
      
      .buttonsWrapper {
        margin-left: auto;
      }
    }
  }
  
  .createModal, .filesModal {
    .userContainer {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    
    .addFilesButton {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 48px;
      height: 48px;

      border-radius: 100%;
      background: #393636;

      transition: 0.3s ease box-shadow;

      &:hover {
        box-shadow: 0 0 8px rgba(255, 255, 255, 0.7);
      }
    }
  }

  .filesModal {
    .title {
      font-size: 18px;
      line-height: 26px;
      
      @media (min-width: 768px) {
        font-size: 22px;
        line-height: 34px;
      }
    }
    
    .footer {
      gap: 16px;
    }
    
    .buttonsWrapper {
      display: flex;
      gap: 10px;
      
      max-width: 290px;
      width: 100%;
      
      button {
        flex: 1 1 0px;
      }
    }

    .notAddedFilesContent {
      align-items: center;
      justify-content: center;
      gap: 16px;
      
      .textContainer {
        display: flex;
        flex-direction: column;
        gap: 4px;
        
        text-align: center;
      }
    }
  }
`;
