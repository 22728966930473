import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuStyles } from './MenuStyles';
import MenuIcon from '../../../../assets/icons/header/MenuIcon';
import DropDown from '../../../other/dropDown/DropDown';
import DeleteIcon from '../../../../assets/icons/table/DeleteIcon';
import { TPost } from '../../../../types/post.types';
import ModalConfirmDecline from '../../../modals/modalConfirmDecline/ModalConfirmDecline';
import workWithResponse from '../../../../helpers/workWithResponse';
import { notification } from '../../../../helpers/notifications/toastify';
import { Api } from '../../../../api';

interface MenuProps {
  post: TPost;
  onPostDelete(id: number): void;
  // handleBlock(user: ApiUser): void;
}

async function deletePost(id: number, onPostDelete: (id: number) => void) {
  await workWithResponse(() => Api.deletePost(id)).then((res) => {
    if (res.error) {
      return notification.error(res.error);
    }

    onPostDelete(id);
  });
}

function Menu({ post, onPostDelete }: MenuProps) {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [activeModalName, setActiveModalName] = useState<'deleting' | null>(null);

  const menuRef = useRef<HTMLDivElement | null>(null);

  return (
    <MenuStyles ref={menuRef}>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      {activeModalName === 'deleting' && (
        <ModalConfirmDecline
          onClose={() => setActiveModalName(null)}
          title="Вы уверены, что хотите удалить этот пост?"
          buttons={
            [
              {
                text: 'Удалить',
                onClick: () => {
                  setActiveModalName(null);
                  deletePost(post.id, onPostDelete);
                },
                theme: 'red',
              },
              {
                text: 'Отмена',
                onClick: () => {
                  setActiveModalName(null);
                },
                theme: 'black',
              },
            ]
          }
        />
      )}

      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          setMenuOpen(!menuOpen);
        }}
      >
        <MenuIcon />
      </button>

      <DropDown onClose={() => setMenuOpen(false)} isOpened={menuOpen} exclude={menuRef}>
        {/* <button type="button" onClick={() => handleBlock(user)}> */}
        {/*  {BlockIcon} */}
        {/*  {' '} */}
        {/*  {user.blocked ? 'Разблокировать' : 'Заблокировать'} */}
        {/* </button> */}

        <button type="button" onClick={() => setActiveModalName('deleting')}>
          {DeleteIcon}
          {' '}
          Удалить
        </button>
      </DropDown>
    </MenuStyles>
  );
}

export default Menu;
