import React from 'react';

function NewsPaper() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="184"
      height="161"
      fill="none"
      viewBox="0 0 184 161"
    >
      <g clipPath="url(#clip0_35_2247)">
        <path
          fill="#505050"
          d="M145.147 3.038a2.723 2.723 0 00-2.689.257L39.928 62.556a8.246 8.246 0 00-2.856 3.218l-4.84-2.8a8.246 8.246 0 012.885-3.2L137.612.497c1.073-.597 2.044-.645 2.784-.197l4.751 2.74z"
        />
        <path
          fill="#363636"
          d="M32.38 157.978c-.906-.507-1.478-1.707-1.478-3.414V67.815v-.597c0-.203 0-.274.042-.412.041-.137.03-.262.053-.394.013-.094.03-.188.054-.28.062-.333.143-.662.244-.985.036-.143.084-.287.132-.43.047-.143.143-.388.214-.597 0 0 0-.042.03-.06.078-.19.155-.376.244-.555.09-.179.191-.388.293-.597l4.864 2.866c-.108.197-.209.4-.31.597-.102.197-.15.334-.215.5a.42.42 0 000 .079 5.897 5.897 0 00-.22.596c-.066.21-.137.412-.191.597a4.427 4.427 0 00-.113.466 8.623 8.623 0 00-.245 2.006v86.743c0 1.725.596 2.936 1.502 3.438l-4.9-2.818z"
        />
        <path
          fill="#252525"
          d="M142.458 3.295L39.928 62.55c-2.326 1.35-4.21 4.955-4.21 8.059v86.749c0 3.104 1.884 4.53 4.21 3.187l102.53-59.255c2.325-1.343 4.209-4.954 4.209-8.058V6.482c0-3.103-1.884-4.53-4.209-3.187z"
        />
        <path
          fill="#CB1F1F"
          d="M136.813 100.514l-91.205 52.731c-1.192.692-2.188-.048-2.188-1.654V67.505c0-1.612.978-3.48 2.188-4.179l91.205-52.725c1.192-.698 2.182.042 2.182 1.648v84.086c0 1.612-.978 3.463-2.182 4.179z"
        />
        <path
          fill="#000"
          d="M136.813 100.514l-91.205 52.731c-1.192.692-2.188-.048-2.188-1.654V67.505c0-1.612.978-3.48 2.188-4.179l91.205-52.725c1.192-.698 2.182.042 2.182 1.648v84.086c0 1.612-.978 3.463-2.182 4.179z"
          opacity="0.1"
        />
        <path
          fill="#000"
          d="M40.994 114.225a3.38 3.38 0 01-1.407 2.692c-.78.454-1.413 0-1.413-1.063a3.372 3.372 0 011.413-2.698c.775-.453 1.407.03 1.407 1.069zM39.587 100.317a2.061 2.061 0 00-.858 1.635v7.486c0 .632.387.919.858.644a2.033 2.033 0 00.853-1.629v-7.462c0-.656-.382-.943-.853-.674z"
        />
        <path
          fill="#1C1C1C"
          d="M130.184 21.56l-72.648 42v82.755l72.648-41.969V21.561z"
        />
        <path
          fill="#1C1C1C"
          d="M130.184 21.56l-2.426-1.396-72.642 41.992 2.42 1.403 72.648-41.998z"
        />
        <path
          fill="#000"
          d="M55.116 62.156l2.42 1.403v82.756l-2.42 1.397V62.156z"
        />
        <path
          fill="#686868"
          d="M66.34 75.462c.04.294.053.592.042.889.011.313-.003.626-.041.937l-4.233 2.448a5.385 5.385 0 01-.041-.89 6.953 6.953 0 01.041-.937l1.312-.758v-9.222l-1.091.596a5.25 5.25 0 01-.042-.883 6.953 6.953 0 01.042-.937l3.66-2.12 3.481 6.633v-6.793l-1.09.633a5.374 5.374 0 01-.042-.89 6.784 6.784 0 01.041-.931l4.173-2.388c.039.295.053.593.042.89.01.313-.003.626-.042.937l-1.228.71v11.049l-2.033 1.194-4.017-7.563v8.058l1.067-.662zM80.862 56.02v3.188c-.215.16-.44.308-.674.442-.222.132-.453.25-.691.352V58.61L76.2 60.509v3.36l1.574-.913v-1.33c.216-.166.443-.315.68-.448.204-.135.424-.243.655-.323v4.5c-.2.172-.42.318-.655.437a4.043 4.043 0 01-.68.34v-1.385l-1.574.907v4.083l3.428-1.981v-1.714c.223-.167.456-.32.698-.46a5.95 5.95 0 01.673-.328v3.534l-7.857 4.543a7.32 7.32 0 01-.041-.89c-.005-.313.01-.626.041-.937l1.228-.704v-9.205l-1.09.633a6.278 6.278 0 01-.042-.89c0-.53.041-.936.041-.936l7.583-4.382zM87.306 54.665l1.896-1.098 1.71 7.509.084-.078s.751-6.16 1.025-9.64l-1.085.687v-.931-.89l3.72-2.149c.034.283.048.569.042.854.007.323-.007.646-.042.967l-.96.555c-.34 3.934-1.311 11.807-1.311 11.807L89.6 63.87l-1.407-6.208-1.407 7.837-2.79 1.606s-1.037-6.566-1.293-10.297l-.96.555v-.937-.89l3.869-2.232c.034.281.048.565.042.848.007.325-.007.65-.042.973l-1.097.633c.197 3.283 1.002 8.47 1.002 8.47l.095-.06 1.693-9.503zM101.356 47.562a5.278 5.278 0 01-.721.471c-.248.154-.51.286-.781.395v-1.29s-.477-.22-1.395.31a3.42 3.42 0 00-1.61 2.645c0 .638.15 1.23.764 1.128l1.788-.269c.96-.13 2.384-.041 2.384 2.388 0 2.43-1.245 4.441-3.576 5.772-2.2 1.277-3.195.299-3.195.299v-2.442c.231-.17.472-.327.72-.471.251-.151.512-.283.782-.394v1.367s.506.364 1.693-.323a3.185 3.185 0 001.722-2.745c0-.932-.596-1.23-1.192-1.14l-1.955.292c-1.353.197-1.788-.83-1.788-2.418 0-2.148 1.568-4.572 3.44-5.652a3.216 3.216 0 012.896-.215l.024 2.292z"
        />
        <path
          fill="#000"
          d="M124.503 30.49L105.13 41.694v13.174l19.373-11.204V30.49z"
        />
        <path
          fill="#CB1F1F"
          d="M124.503 51.334L62.15 87.382v-3.408l62.353-36.048v3.408z"
        />
        <path
          fill="#686868"
          d="M81.923 111.39L62.15 122.82V91.644l19.773-11.431v31.177z"
        />
        <path
          fill="#1C1C1C"
          d="M78.597 87.101l-13.115 7.587c-.244.137-.44 0-.44-.328a1.04 1.04 0 01.44-.83l13.115-7.58c.238-.144.435 0 .435.328a1.04 1.04 0 01-.435.823zM78.597 92.694l-13.115 7.581c-.244.137-.44 0-.44-.328a1.051 1.051 0 01.44-.836l13.115-7.58c.238-.138.435 0 .435.328a1.052 1.052 0 01-.435.835zM78.597 98.281l-13.115 7.581c-.244.143-.44 0-.44-.328a1.023 1.023 0 01.44-.83l13.115-7.586c.238-.138.435 0 .435.334a1.045 1.045 0 01-.435.83zM78.597 103.868l-13.115 7.587c-.244.137-.44 0-.44-.328a1.039 1.039 0 01.44-.836l13.115-7.581c.238-.143.435 0 .435.329a1.044 1.044 0 01-.435.829zM78.597 109.461l-13.115 7.581c-.244.138-.44 0-.44-.328a1.05 1.05 0 01.44-.836l13.115-7.581c.238-.137.435.001.435.329a1.052 1.052 0 01-.435.835zM78.597 89.77l-13.115 7.58c-.244.138-.44 0-.44-.328a1.039 1.039 0 01.44-.836l13.115-7.58c.238-.144.435 0 .435.328a1.058 1.058 0 01-.435.835zM78.597 95.356l-13.115 7.581c-.244.144-.44 0-.44-.328a1.05 1.05 0 01.44-.836l13.115-7.58c.238-.138.435 0 .435.328a1.052 1.052 0 01-.435.835zM78.597 100.944l-13.115 7.586c-.244.138-.44 0-.44-.334a1.027 1.027 0 01.44-.83l13.115-7.586c.238-.138.435 0 .435.334a1.042 1.042 0 01-.435.83zM78.597 106.537l-13.115 7.581c-.244.137-.44 0-.44-.329a1.042 1.042 0 01.44-.835l13.115-7.581c.238-.143.435 0 .435.328a1.056 1.056 0 01-.435.836z"
        />
        <path
          fill="#000"
          d="M124.503 107.599l-38.204 22.092V77.682l38.204-22.086V107.6z"
        />
        <path
          fill="#CB1F1F"
          d="M88.987 78.566l33.209-19.197c.465-.275.847-.054.847.483V87.15a1.879 1.879 0 01-.847 1.463l-33.203 19.202c-.471.269-.846.048-.846-.489V80.028a1.87 1.87 0 01.84-1.463z"
        />
        <path
          fill="#1C1C1C"
          d="M88.987 78.566l33.209-19.197c.465-.275.847-.054.847.483V87.15a1.879 1.879 0 01-.847 1.463l-33.203 19.202c-.471.269-.846.048-.846-.489V80.028a1.87 1.87 0 01.84-1.463z"
          opacity="0.8"
        />
        <path
          fill="#CB1F1F"
          d="M113.779 75.115l-5.55 9.832 3.899 5.061-12.882-11.102-11.1 24.073v4.358c0 .537.376.758.847.489l33.203-19.203a1.871 1.871 0 00.847-1.462v-3.552l-9.264-8.494zM117.612 66.102c.918-.531 1.663-.102 1.663.961a3.69 3.69 0 01-1.663 2.89c-.918.53-1.663.1-1.663-.962a3.69 3.69 0 011.663-2.889z"
        />
        <path
          fill="#353535"
          d="M94.96 109.121l27.958-16.164v11.198L94.96 120.319v-11.198z"
        />
        <path
          fill="#CB1F1F"
          d="M92.415 121.788L62.15 139.283v-11.192l30.265-17.501v11.198z"
        />
      </g>
      <defs>
        <clipPath id="clip0_35_2247">
          <path fill="#fff" d="M0 0H184V161H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default NewsPaper;
