import React from 'react';

function CrossIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        stroke="#BF2B2B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M23.592 9.634L10.008 23.218M9.592 9.634l13.584 13.584"
      />
    </svg>
  );
}

export default CrossIcon;
