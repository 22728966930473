import styled from 'styled-components';

export const PostsStyles = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  
  .postsEmpty {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    border-radius: 4px;
    background: var(--cards, #171616);

    color: var(--red-2, #CB1F1F);
    text-align: center;
    font-family: Gilroy-SemiBold, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
  }
  
  .createPost {
    margin-bottom: 16px;
  }
  
  .infinite-scroll-component {
    gap: 16px;
    
    padding: 0 16px;
  }
  
  .postsHeadWrapper {
    padding: 0 16px;
    
    &.empty {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }
`;
