import IApi from './IApi';
import ApiResponse from './entities/ApiResponse';
import { ApiUser, ChangeUserData } from '../types/user.types';
import { ApiAuthResponse, LoginData, RegisterData } from '../types/authentefication.types';
import ApiChatAuthResponse, { IChatHistoryItem, IMessage } from '../types/chat.types';
import { ApiFile } from '../types/file.types';
import { mapFromFileToFormData } from './mappers/mappers';
import { TCreatedPost, TCreatePost, TPostArray } from '../types/post.types';
import { TConnectsByStatuses } from '../types/connects.types';

export default class Api implements IApi {
  private baseUrl: string = process.env.REACT_APP_API_HOST!;

  private async fetchData(path: string, requestOptions: any): Promise<any> {
    try {
      // console.log(`${this.getBaseUrl(path)}${path}`, { ...requestOptions });
      const response = await fetch(`${this.baseUrl}${path}`, { ...requestOptions });
      const statusCode = response.status;
      const data = await response.json();
      return {
        data,
        statusCode,
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('FETCH ERROR:', e);
      throw new Error(`API Fetch error: ${e}`);
    }
  }

  private async getData(path: string, refresh?: boolean, chatToken?: string, tokenRequired = true): Promise<any> {
    const myHeaders: { [key: string]: string } = {};

    if (path === '/chats/refresh') {
      myHeaders['X-Chat-Token-Refresh'] = `Bearer ${chatToken}`;
    }

    if (tokenRequired) {
      const accessToken = localStorage.getItem('access_token');
      myHeaders.Authorization = `Bearer ${accessToken}`;
    }

    if (refresh) {
      const refreshToken = localStorage.getItem('refresh_token');
      myHeaders.Authorization = `Bearer ${refreshToken}`;
    }

    if (chatToken) {
      myHeaders['X-Chat-Token'] = `Bearer ${chatToken}`;
    }

    const requestOptions: {
      method: string;
      redirect: 'follow' | 'error' | 'manual' | undefined;
      headers: { [key: string]: string };
    } = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
    };
    return this.fetchData(path, requestOptions);
  }

  private async postData(
    path: string,
    data?: any,
    formData?: any,
    chatToken?: string,
    tokenRequired = true,
  ): Promise<any> {
    const myHeaders: { [key: string]: string } = {};
    if (!formData) {
      myHeaders['Content-Type'] = 'application/json';
    }

    if (tokenRequired) {
      const accessToken = localStorage.getItem('access_token');
      myHeaders.Authorization = `Bearer ${accessToken}`;
    }

    if (chatToken) {
      myHeaders['X-Chat-Token'] = `Bearer ${chatToken}`;
    }

    const requestOptions: {
      method: string;
      headers: { [key: string]: string };
      body: string;
      redirect: 'follow' | 'error' | 'manual' | undefined;

      mode: string,
      cache: string,
      credentials: string,
      referrerPolicy: string,
    } = {
      method: 'POST',
      headers: myHeaders,
      body: formData ? data : JSON.stringify(data),
      redirect: 'follow',

      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      referrerPolicy: 'no-referrer', // no-referrer, *client
    };

    return this.fetchData(path, requestOptions);
  }

  async putData(
    path: string,
    data: any,
    formData?: boolean,
    chatToken?: string,
  ) {
    const accessToken = localStorage.getItem('access_token');
    const myHeaders: { [key: string]: string } = {};
    if (!formData) {
      myHeaders['Content-Type'] = 'application/json';
    }

    if (accessToken) {
      // @ts-ignore
      myHeaders.Authorization = `Bearer ${accessToken}`;
    }

    if (chatToken) {
      myHeaders['X-Chat-Token'] = `Bearer ${chatToken}`;
    }

    const requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: formData ? data : JSON.stringify(data),
      redirect: 'follow',
    };

    return this.fetchData(path, requestOptions);
  }

  private async deleteData(path: string, data?: any, chatToken?: string): Promise<any> {
    const accessToken = localStorage.getItem('access_token');
    const myHeaders: { [key: string]: string } = {};

    if (accessToken) {
      // @ts-ignore
      myHeaders.Authorization = `Bearer ${accessToken}`;
    }

    const requestOptions = {
      method: 'Delete',
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: 'follow',
    };

    return this.fetchData(path, requestOptions);
  }

  // eslint-disable-next-line class-methods-use-this
  public async getMyAccount(): Promise<ApiResponse<ApiUser>> {
    return this.getData('/users/me');

    // return Promise.resolve({ statusCode: 200, data: fakeUserResponse });
  }

  // eslint-disable-next-line class-methods-use-this
  public async logout(): Promise<ApiResponse<any>> {
    return this.getData('/logout');
  }

  // eslint-disable-next-line class-methods-use-this
  public async changeUserData(data: ChangeUserData): Promise<ApiResponse<ApiUser>> {
    const response: ApiResponse<ApiUser> = await this.putData(`/users/${data.id}`, data);
    return response;
    // return Promise.resolve(fakeChangedUserResponse);
  }

  // eslint-disable-next-line class-methods-use-this
  public async login(data: LoginData): Promise<ApiResponse<ApiAuthResponse>> {
    // return Promise.resolve({
    //   statusCode: 200,
    //   data: {
    //     user_id: 1, access_token_expired_at: 2, refresh_token_expired_at: 12, refresh_token: '2123', access_token: '123',
    //   },
    // });

    const response: ApiResponse<ApiAuthResponse> = await this.postData('/login', data);

    return response;
    // return this.postData('/login', data, undefined, undefined, false);
  }

  public async register(data: RegisterData): Promise<ApiResponse<ApiAuthResponse>> {
    return this.postData('/registration', data);
  }

  // eslint-disable-next-line class-methods-use-this
  public async vereficateEmail(code: string, email: string): Promise<ApiResponse<{message: string}>> {
    return this.postData('/notifies/validate', { email, code });

    // return Promise.resolve(fakeUserResponse);
  }

  public async getVerificationPassword(email: string): Promise<ApiResponse<{code: string}>> {
    return this.postData('/notifies/', { email });
  }

  public async refreshChatToken(): Promise<ApiResponse<ApiAuthResponse>> {
    const token = await this.getData('/chats/refresh');

    return {
      statusCode: token.statusCode,
      data: {
        ...token.data,
        access_token_expired_at: Date.now() + token.data.access_token_expired_at,
        refresh_token_expired_at: Date.now() + token.data.refresh_token_expired_at,
      },
    };
  }

  // eslint-disable-next-line class-methods-use-this
  public async getUser(id: number): Promise<ApiResponse<ApiUser>> {
    return this.getData(`/users/${id}`);
    // return Promise.resolve({ statusCode: 200, data: fakeUser });
  }

  // eslint-disable-next-line class-methods-use-this
  public async getChats(id: number): Promise<ApiResponse<{data: IChatHistoryItem[], count: number}>> {
    // return Promise.resolve({ statusCode: 200, data: chatHistoryFakeData });
    return this.getData(`/chats/list/${id}?limit=1000&offset=0`);
  }

  // eslint-disable-next-line class-methods-use-this
  public async getMyChats(): Promise<ApiResponse<IChatHistoryItem[]>> {
    // return Promise.resolve({ statusCode: 200, data: chatHistoryFakeData });
    return this.getData('/chats/me', false);
  }

  public async getChatByChatId(chatId: number): Promise<ApiResponse<IChatHistoryItem>> {
    // return Promise.resolve({ statusCode: 200, data: chatHistoryFakeData });
    return this.getData(`/chats/${chatId}`, false);
  }

  // // eslint-disable-next-line class-methods-use-this
  // public async getChat(id: number): Promise<ApiResponse<IChatHistoryItem>> {
  //   return Promise.resolve({ statusCode: 200, data: chatHistoryFakeData });
  //   // return this.getData(`/chats/${id}`);
  // }

  // eslint-disable-next-line class-methods-use-this
  public async createChat(user1Id: number, user2Id: number): Promise<ApiResponse<IChatHistoryItem>> {
    return this.postData('/chats/', { user_1_id: user1Id, user_2_id: user2Id });
  }

  // eslint-disable-next-line class-methods-use-this
  public async getChatToken(): Promise<ApiResponse<ApiChatAuthResponse>> {
    const token = await this.getData('/chats/tokens/');

    return {
      statusCode: token.statusCode,
      data: {
        ...token.data,
        access_token_expired_at: Date.now() + token.data.access_token_expired_at,
        refresh_token_expired_at: Date.now() + token.data.refresh_token_expired_at,
      },
    };
  }

  // eslint-disable-next-line class-methods-use-this
  public async deleteChat(id: number): Promise<ApiResponse<{message: string}>> {
    return Promise.resolve({ statusCode: 200, data: { message: 'OK' } });
    // return this.deleteData(`/chats/${id}`);
  }

  // eslint-disable-next-line class-methods-use-this
  public async createMessage({
    content, chatId, userId, chatToken,
  }: {content: string, userId: number, chatId: number, chatToken: string}): Promise<ApiResponse<IMessage>> {
    // return Promise.resolve({ statusCode: 200, data: { ...fakeMassage, message } });
    return this.postData('/messages/', { user_id: userId, chat_id: chatId, content }, undefined, chatToken);
  }

  // eslint-disable-next-line class-methods-use-this
  getMessages({
    chatId, limit, offset, chatToken,
  }: {chatId: number, limit: number, offset: number, chatToken: string}): Promise<ApiResponse<{count: number, data: IMessage[] }>> {
    // return Promise.resolve({ statusCode: 200, data: fakeMessages });
    return this.getData(`/messages/${chatId}/chats?limit=${limit || 10}&offset=${offset || 0}&order=desc&sortBy=created_at`, undefined, chatToken);
  }

  // eslint-disable-next-line class-methods-use-this
  public async createFile(file: File): Promise<ApiResponse<ApiFile>> {
    const formData = mapFromFileToFormData(file);

    console.log(formData, file);

    return this.postData('/files/', formData, true);

    // return Promise.resolve({ data: fakeFile, statusCode: 200 });
  }

  // eslint-disable-next-line class-methods-use-this
  public async getFile(id: number): Promise<ApiResponse<ApiFile>> {
    return this.getData(`/files/${id}`);

    // return Promise.resolve({ data: fakeFile, statusCode: 200 });
  }

  public async updateFile(id: number, data: { file: File, width?: number, height?: number }): Promise<ApiResponse<ApiFile>> {
    const formDataFromData = mapFromFileToFormData(data.file);
    return this.putData(`/file/${id}`, formDataFromData, true);

    // return Promise.resolve({ data: undefined, statusCode: 200 });
  }

  // eslint-disable-next-line class-methods-use-this
  public async deleteFile(id: number): Promise<ApiResponse<{ message: string }>> {
    return this.deleteData(`/file/${id}`);

    // return Promise.resolve({ data: { message: 'OK' }, statusCode: 200 });
  }

  // eslint-disable-next-line class-methods-use-this
  public async createPost(data: TCreatePost): Promise<ApiResponse<TCreatedPost>> {
    // return Promise.resolve({ statusCode: 200, data: fakePost });

    return this.postData('/posts/', data);
  }

  // eslint-disable-next-line class-methods-use-this
  public async getPosts(offset: number, limit?: number): Promise<ApiResponse<{ data: TPostArray, total: number }>> {
    // return Promise.resolve({ statusCode: 200, data: { total: 20, data: [fakePost, fakePost, fakePost] } });

    return this.getData(`/posts/?offset=${offset || 0}&limit=${limit || 10}`);
  }

  // eslint-disable-next-line class-methods-use-this
  public async getPostsByUserId(user_id: number, offset: number, limit?: number): Promise<ApiResponse<{ data: TPostArray, count: number }>> {
    // return Promise.resolve({ statusCode: 200, data: { total: 20, data: [fakePost, fakePost, fakePost] } });

    return this.getData(`/posts/${user_id}/users?offset=${offset || 0}&limit=${limit || 10}`);
  }

  public async deletePost(id: number): Promise<ApiResponse<{message: string}>> {
    return this.deleteData(`/posts/${id}`);
  }

  // // eslint-disable-next-line class-methods-use-this
  // public async searchUsersToConnect({
  //   limit, offset, category, login,
  // }: {limit?: number, offset: number, category: 'all' | 'connects' | 'followings' | 'followers', login?: string }): Promise<ApiResponse<{data: TConnect[], count: number }>> {
  //   let path = `/connects/?limit=${limit || 10}&offset=${offset || 0}&category=${category}`;
  //
  //   if (login) {
  //     path += `&login=${login}`;
  //   }
  //
  //   return Promise.resolve({ statusCode: 200, data: { count: 100, data: fakeConnectsData.slice(offset, offset + (limit || 10)) } });
  //
  //   // return this.getData(path);
  // }

  public async getConnects(): Promise<ApiResponse<TConnectsByStatuses>> {
    return this.getData('/connections/');
  }

  public async searchUsersToConnect({ login }: {login: string}): Promise<ApiResponse<ApiUser[]>> {
    return this.getData(`/connections/${login}`);
  }

  public async subscribeConnect({ user_id }: {user_id: number }): Promise<ApiResponse<{message: string}>> {
    return this.postData('/connections/', { follower_id: user_id });
  }

  public async unsubscribeConnect({ user_id }: {user_id: number }): Promise<ApiResponse<{message: string }>> {
    return this.deleteData(`/connections/${user_id}/subscriptions`);
  }
}
