import React from 'react';

function Vidget1() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
    >
      <g clipPath="url(#clip0_37_2042)">
        <path
          fill="#CB1F1F"
          d="M12.5 0C5.873 0 .5 5.373.5 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5.838 7.957l-2.594 2.457 1.645 6.627-.982.982-3.141-5.122-.072-.073-3.231 3.06c-.018.018-.039.032-.058.05l-.103 2.358-3.598-3.597 2.358-.104c.017-.019.032-.04.05-.058l3.06-3.23-.027-.027-5.168-3.187.982-.981 6.597 1.676 2.487-2.627a1.27 1.27 0 111.795 1.796z"
        />
      </g>
      <defs>
        <clipPath id="clip0_37_2042">
          <path fill="#fff" d="M0 0H24V24H0z" transform="translate(.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Vidget1;
