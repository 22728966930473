import React from 'react';
import { Link } from 'react-router-dom';
import { SidebarNavStyles } from './SidebarNavStyles';
import { navLinks } from '../../../constants/navLinks';

function SidebarNav() {
  return (
    <SidebarNavStyles className="navContainer">
      <div className="navWrapper">
        <nav className="textBase18">
          {navLinks.map((link) => (
            <li key={link.path}>
              <Link to={link.path}>
                {link.name}
              </Link>
            </li>
          ))}
        </nav>
      </div>
    </SidebarNavStyles>
  );
}

export default SidebarNav;
