import styled from 'styled-components';

export const MyPageStyles = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  
  .userMenu {
      grid-column: 10/13;

      display: flex;
      justify-content: space-between;
      gap: 20px;
      margin-bottom: 20px;

      @media (max-width: 500px) {
        display: grid;
        
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
      }

      a {
        &:nth-child(1) {
          justify-content: space-between;

          span {
            text-align: center;

            &:nth-child(1) {
              font-family: Gilroy-SemiBold, sans-serif;
              font-size: 22px;
              font-style: normal;
              font-weight: 400;
              line-height: 34px;
            }

            &:nth-child(2) {
              font-family: Gilroy-Bold, sans-serif;
              font-size: 28px;
              font-style: normal;
              font-weight: 400;
              line-height: 42px;
              letter-spacing: 0.56px;
            }
          }
        }

        &:nth-child(2), &:nth-child(3) {
          justify-content: center;
          align-items: center;
          gap: 16px;
          text-align: center;
        }
      }

      a {
        flex: 1;
        display: flex;
        flex-direction: column;

        padding: 35px 5px;
        box-sizing: border-box;

        border-radius: 4px;
        border: 1px solid var(--red-2, #CB1F1F);
        color: #BF2B2B;

        transition: 0.3s ease all;

        &:hover {
          box-shadow: 0px 2px 6px 2px rgba(213, 76, 76, 0.50), 0px 0px 4px 1px rgba(213, 76, 76, 0.60);
        }

        svg {
          width: 100%;
        }
      }
    }

    .stickerPacks {
      margin-bottom: 20px;

      .title {
        padding: 8px;

        border-radius: 4px 4px 0px 0px;
        border-top: 1px solid var(--red-3, #A21919);
        border-right: 1px solid var(--red-3, #A21919);
        border-left: 1px solid var(--red-3, #A21919);
        background: var(--background, #0F0E0E);
        color: var(--white, #FDFDFD);
        text-align: center;
      }

      .body {
        display: flex;
        justify-content: space-between;
        gap: 10px;

        padding: 16px 32px;

        border-radius: 0px 0px 4px 4px;
        background: var(--red-2, #CB1F1F);
        
        @media (max-width: 600px) {
          svg {
            width: 24px;
            height: 24px;
          }

          padding: 16px;
        }
      }
    }

    .mainPageFooter {
      display: flex;
      flex-direction: column;

      flex-grow: 1;
      
      min-height: 700px;

      .footerHeader {
        display: flex;
        justify-content: space-between;

        padding: 16px;

        border-radius: 4px 4px 0px 0px;
        background: var(--red-2, #CB1F1F);

        color: var(--white, #FDFDFD);
      }

      .footerBody {
        display: flex;
        flex-direction: column;

        flex: 1;
        flex-grow: 1;
        box-sizing: border-box;
        border: 1px solid var(--red-2, #CB1F1F);
        
        padding: 16px 0;
      }

      .widgets {
        margin-bottom: 16px;
        padding: 0 16px;

        .widgetsHeader {
          padding: 8px 16px;

          border-radius: 4px 4px 0px 0px;
          border-top: 1px solid var(--red-3, #A21919);
          border-right: 1px solid var(--red-3, #A21919);
          border-left: 1px solid var(--red-3, #A21919);
          background: var(--background, #0F0E0E);

          color: var(--white, #FDFDFD);
          text-align: center;
        }

        .widgetsList {
          border-radius: 0px 0px 4px 4px;
          background: #272424;

          display: flex;
          justify-content: space-around;
          gap: 8px;
          padding: 8px 16px;

          @media (max-width: 500px) {
            flex-direction: column;
            gap: 12px;  
          }

          .widget {
            display: flex;
            align-items: center;
            gap: 12px;

            color: var(--white, #FDFDFD);
            
            @media (max-width: 500px) {
              gap: 6px;
            }
          }
        }
      }
    }
`;
