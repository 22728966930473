import React, { Fragment, useEffect, useState } from 'react';
import NewsPaper from '../../../assets/icons/main/NewsPaper';
import { TPost } from '../../../types/post.types';
import { PostsStyles } from './PostsStyles';
import CreatePost from '../../posts/create/CreatePost';
import workWithResponse from '../../../helpers/workWithResponse';
import { Api } from '../../../api';
import { notification } from '../../../helpers/notifications/toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import MainLoader from '../../other/loaders/MainLoader';
import Post from '../../posts/post/Post';
import { useWindowSize } from 'usehooks-ts';
import { useAppSelector } from '../../../state/hooks';
import Loader from '../../loader/Loader';
import Adversting from '../../adversting/Adversting';

function Posts() {
  const [posts, setPosts] = useState<TPost[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { width } = useWindowSize();
  const { data: user } = useAppSelector((state) => state.user);

  function handlePostCreate(post: TPost) {
    setPosts([post, ...posts]);
  }

  function onPostDelete(postId: number) {
    setPosts(posts.filter((post) => postId !== post.id));
  }

  async function getPosts() {
    try {
      setIsLoading(true);

      await workWithResponse(() => Api.getPosts(posts.length, 10)).then((res) => {
        if (res.error) {
          notification.error('Произошла ошибка при загрузке новых постов');
        } else if (res.data) {
          setPosts([...posts, ...res.data.data]);

          if (total !== res.data.total) {
            setTotal(res.data.total);
          }
        }
      });
    } catch (e) {
      console.error(e);

      if (e instanceof Error && e.message) {
        notification.error(e.message);
      } else {
        notification.error('Произошла неизвестная ошибка');
      }
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getPosts();
  }, []);

  return (
    <PostsStyles>
      <div className="postsHeadWrapper">
        <CreatePost onPostCreate={handlePostCreate} />

        {posts.length === 0 && isLoading && (
          <Loader />
        )}

        {posts.length === 0 && !isLoading && (
          <div className="postsEmpty footerBodyPadding">
            <NewsPaper />

            <span>Лента новостей пока пустая</span>
          </div>
        )}
      </div>

      <div
        id="scrollableDiv"
        className="scrollableDiv"
        style={{
          height: window.innerWidth < 1001 ? window.innerHeight - 194 : 800,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {posts.length > 0 && (
        <InfiniteScroll
          dataLength={posts.length}
          next={() => {
            getPosts();
          }}
          style={{ display: 'flex', flexDirection: 'column' }} // To put endMessage and loader to the top.
          hasMore={total > posts.length}
          loader={<MainLoader size={16} />}
          scrollableTarget="scrollableDiv"
        >
          {posts.map((post, index) => (
            <Fragment key={post.id}>
              {/* {index !== 0 && index % 3 === 0 && width > 1000 && 'Reklama'} */}
              {index === 3 && width > 1000 && <Adversting />}

              <Post post={post} onPostDelete={onPostDelete} />
            </Fragment>
          ))}
        </InfiniteScroll>
        )}
      </div>
    </PostsStyles>
  );
}

export default Posts;
