import React from 'react';

function Vidget2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
    >
      <g fill="#CB1F1F" clipPath="url(#clip0_37_2067)">
        <path d="M15.872 11.482a.705.705 0 100-1.41.705.705 0 000 1.41zM17.009 10.346a.705.705 0 100-1.41.705.705 0 000 1.41zM8.069 9.425H7.03v.985h-.985v1.038h.985v.985H8.07v-.985h.985V10.41H8.07v-.985z" />
        <path d="M12.5 0C5.873 0 .5 5.373.5 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.165 17.287h-1.062l-2.289-3.65H9.686l-2.289 3.65H6.335a1.517 1.517 0 01-1.517-1.516l.337-5.308c0-.017.002-.03.003-.046l-.003-.051A3.245 3.245 0 018.4 7.121c.743 0 1.258.252 1.805.673.7-.204 1.048-.379 1.998-.431h.594c.95.052 1.298.227 1.998.43.547-.42 1.061-.672 1.804-.672a3.245 3.245 0 013.246 3.245c0 .017-.003.034-.003.05s.003.03.003.047l.337 5.308c0 .837-.68 1.516-1.517 1.516z" />
        <path d="M18.145 11.482a.705.705 0 100-1.409.705.705 0 000 1.41zM17.009 12.62a.705.705 0 100-1.41.705.705 0 000 1.41z" />
      </g>
      <defs>
        <clipPath id="clip0_37_2067">
          <path fill="#fff" d="M0 0H24V24H0z" transform="translate(.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Vidget2;
