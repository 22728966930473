const colors = {
  main1: '#BA4040',
  main2: '#CB1F1F',
  main3: '#CB1F1F',
  main4: '#CB1F1F',
  main5: '#CB1F1F',
  main6: '#CB1F1F',
  secondary1: '#F5F5F5',
  secondary2: '#D9D9D9',
  secondary3: '#8C8C8C',
  secondary4: '#595959',
  secondary5: '#1C1C1C',
  secondary6: '#141414',
};

export default colors;
