import React, { useEffect, useState } from 'react';
import { CreatePostStyles } from './CreatePostStyles';
import UserLogo from '../../other/userLogo/UserLogo';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import Modal from '../../modal/Modal';
import { CameraIcon } from '../../../assets/icons/createPost/CameraIcon';
import { FormikState, useFormik } from 'formik';
import * as Yup from 'yup';
import { notification } from '../../../helpers/notifications/toastify';
import workWithResponse from '../../../helpers/workWithResponse';
import { Api } from '../../../api';
import TextareaAutosize from 'react-textarea-autosize';
import Button from '../../buttons/button/Button';
import AddFilesModal from './addFilesModal/AddFilesModal';
import ModalConfirmDecline from '../../modals/modalConfirmDecline/ModalConfirmDecline';
import { TPost, TPostFile } from '../../../types/post.types';
import { TrashIcon } from '../../../assets/icons/createPost/TrashIcon';

interface FormValues {
  text: string,
  files: TPostFile[],
}

interface IAddedImage {
  img: TPostFile,
  onDelete: (id: number) => void
}

const AddedImage = ({ img, onDelete }: IAddedImage) => (
  <div className="imagePreview">
    <img src={img.url} alt="" />

    <div className="imageButtons">
      <button type="button" onClick={() => onDelete(img.id)}>
        {TrashIcon}
      </button>

      {/* <button type="button" onClick={handleUploadButtonClick}> */}
      {/*  {EditIcon} */}
      {/* </button> */}
    </div>
  </div>
);

export type CreatePostModalNames = 'create' | 'discard' | 'addFiles' | 'warningBeforeClose' | null;

let prevActiveModalName: CreatePostModalNames = null;
const unsavedFilesBeforeClose: File[] = [];

interface OnCloseProps {
  modalName: CreatePostModalNames,
  values: FormValues,
  setActiveModalName: React.Dispatch<React.SetStateAction<CreatePostModalNames>>,
  resetForm: (nextState?: Partial<FormikState<FormValues>>) => void,
  dontCloseWarning?: boolean,
}

function onClose({
  setActiveModalName, modalName, values, resetForm, dontCloseWarning,
}: OnCloseProps) {
  if (values.text || values.files.length > 0 || dontCloseWarning) {
    prevActiveModalName = modalName;

    setActiveModalName('warningBeforeClose');
  } else {
    resetForm();

    setActiveModalName(null);
  }
}

interface CreatePostProps {
  onPostCreate: (post: TPost) => void;
}

function CreatePost({ onPostCreate }: CreatePostProps) {
  const { data: user } = useAppSelector((state) => state.user);

  const [activeModalName, setActiveModalName] = useState<CreatePostModalNames>(null);

  const {
    handleChange, handleSubmit, handleBlur, setValues, values, errors, touched, isSubmitting, setSubmitting, setFieldValue, resetForm,
  } = useFormik<FormValues>({
    initialValues: {
      text: '',
      files: [],
    },
    onSubmit: async (values) => {
      setSubmitting(true);

      if (values && (values.text || values.files.length > 0)) {
        await workWithResponse(() => Api.createPost({ user_id: user!.id, text: values.text, files: values.files.map((file) => file.id) })).then((res) => {
          if (res.error) {
            // @ts-ignore
            const error = (res.data as {message: string})?.message || 'Неизвестная ошибка';

            notification.error(error);
          } else if (res.data) {
            const createdPost: TPost = {
              ...res.data,
              user: {
                login: user!.login,
                id: user!.id,
                file_url: user!.file_url,
              },
              files: values.files,
            };

            onPostCreate(createdPost);
            setActiveModalName(null);
          }
        }).catch((e) => {
          console.error(e);
          notification.error('Произошла ошибка на сервере');
        });

        setSubmitting(false);
      } else {
        notification.error('Контент поста не может быть пустым. Для сохранения поста, добавьте файл или текст.');
      }
    },
  });

  useEffect(() => {
    if (activeModalName === null) {
      resetForm();
    }
  }, [activeModalName]);

  return (
    <CreatePostStyles className="createPost">
      <button aria-label="open create post modal" className="openButton" type="button" onClick={() => setActiveModalName('create')}>
        <UserLogo size={32} name={user!.login} logo={user && user.file_url ? user.file_url : null} />

        <span className="textBase18">Новая публикация</span>
      </button>

      {activeModalName === 'create' && (
        <Modal
          onClose={() => onClose({
            setActiveModalName, modalName: 'create', values, resetForm,
          })}
          className="createModal postModal"
        >
          <div className="head">
            <div className="userContainer">
              <UserLogo size={48} name={user!.login} logo={user && user.file_url ? user.file_url : null} />

              <span className="textBase18">{user!.login}</span>
            </div>

            <div className="content">
              <TextareaAutosize minRows={7} className="autoResizeTextArea" name="text" onChange={handleChange} value={values.text} placeholder="О чем хотите поговорить?" maxLength={1024} />

              <div className="filesContainer">
                <div className="imagesContainer">
                  {values.files.map((file) => {
                    if (file.mime_type.startsWith('image')) {
                      return <AddedImage img={file} onDelete={(deletedFileId) => setFieldValue('files', values.files.filter((file) => file.id !== deletedFileId))} />;
                    }

                    return null;
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className="footer">
            {values.files.length === 0 && <button type="button" className="addFilesButton" onClick={() => setActiveModalName('addFiles')}>{CameraIcon}</button>}

            <div className="buttonsWrapper">
              <Button disabled={isSubmitting} theme="red" onClick={handleSubmit} text="Опубликовать" />
            </div>
          </div>
        </Modal>
      )}

      {activeModalName === 'addFiles' && (
        <AddFilesModal
          onClose={(modalName, dontCloseWarning) => onClose({
            setActiveModalName, modalName, values, resetForm, dontCloseWarning,
          })}
          uploadedFilesCount={values.files.length}
          handleBack={() => setActiveModalName('create')}
          handleSubmit={(uploadedFiles) => {
            setFieldValue('files', [...values.files, ...uploadedFiles]);
            setActiveModalName('create');
          }}
        />
      )}

      {activeModalName === 'warningBeforeClose' && (
        <ModalConfirmDecline
          onClose={() => setActiveModalName(null)}
          title="Данные публикации могут быть потеряны!"
          description="Вы уверены, что хотите закрыть это окно без публикации? Все введенные вами сведения будут утеряны."
          buttons={
            [
              {
                text: 'Вернуться к публикации',
                onClick: () => {
                  setActiveModalName(prevActiveModalName);
                },
                theme: 'red',
              },
              {
                text: 'Все равно закрыть',
                onClick: () => {
                  setActiveModalName(null);
                },
                theme: 'black',
              },
            ]
          }
        />
      )}

    </CreatePostStyles>
  );
}

export default CreatePost;
