import React, { lazy, Suspense } from 'react';
import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAppSelector } from './state/hooks';

import { RingLoader } from 'react-spinners';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyPage from './pages/myPage/MyPage';
import NewsFeed from './pages/newsFeed/NewsFeed';
import { developingMode } from './index';

const Main = lazy(() => import('./pages/main/Main'));
const Login = lazy(() => import('./pages/authentefications/login/Login'));
const Registration = lazy(() => import('./pages/authentefications/registration/Registration'));
const Messages = lazy(() => import('./pages/messages/Messages'));
const ChatPage = lazy(() => import('./pages/chat/ChatPage'));
const Announces = lazy(() => import('./pages/announces/Announces'));
const Connects = lazy(() => import('./pages/connects/Connects'));
const Shops = lazy(() => import('./pages/shops/Shops'));
const Library = lazy(() => import('./pages/library/Library'));
const Profile = lazy(() => import('./pages/profile/Profile'));

function App() {
  const { loggedIn, data: user } = useAppSelector((state) => state.user);

  if (developingMode === 'development') {
    console.log(process.env.REACT_APP_BASE_URL);
    console.log(process.env.REACT_APP_CENTRIFUGA_HOST);
  }

  console.log(developingMode);

  return (
    <>
      <ToastContainer
        limit={10}
        style={{
          maxWidth: '500px',
          width: '100%',
          paddingLeft: '16px',
          paddingRight: '16px',
        }}
      />

      <Suspense
        fallback={(
          <div
            className="spinner"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100vw',
              height: '100vh',
            }}
          >
            <RingLoader
              color="#A21919"
              size={100}
            />
          </div>
        )}
      >
        {loggedIn ? (
          <Routes>
            <Route path="/" element={<MyPage />} />
            <Route path="/news" element={<NewsFeed />} />
            <Route path="/messages" element={<Messages />} />

            <Route path="/announces" element={<Announces />} />
            <Route path="/connects" element={<Connects />} />
            <Route path="/shops" element={<Shops />} />
            <Route path="/library" element={<Library />} />
            <Route path="/profile" element={<Profile />} />

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/registration" element={<Registration />} />

            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        )}
      </Suspense>
    </>
  );
}

export default App;
