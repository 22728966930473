import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiUser } from '../../types/user.types';

export interface UsersState {
  users: {[key: string]: ApiUser}
}

const initialState: UsersState = {
  users: {},
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUser: (state, action: PayloadAction<ApiUser>) => ({
      ...state,
      users: {
        ...state.users,
        [String(action.payload.id)]: action.payload,
      },
    }),
  },
});

const { actions, reducer } = userSlice;
// Extract and export each action creator by name
export const {
  addUser,
} = actions;
// Export the reducer, either as a default or named export
export default reducer;
