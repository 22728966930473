import React from 'react';

function Vidget3() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
    >
      <g fill="#CB1F1F" clipPath="url(#clip0_37_2075)">
        <path d="M12.5 5.998a5.983 5.983 0 00-5.976 5.976A5.983 5.983 0 0012.5 17.95a5.983 5.983 0 005.976-5.976A5.983 5.983 0 0012.5 5.998zm-3.302 9.277l1.696-4.033 2.338 2.337-4.034 1.696zm4.911-2.58l-2.332-2.33 4.024-1.693-1.692 4.024z" />
        <path d="M12.5 0C5.873 0 .5 5.373.5 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 19.195c-3.982 0-7.221-3.24-7.221-7.221 0-3.982 3.239-7.221 7.221-7.221s7.221 3.239 7.221 7.22c0 3.982-3.24 7.222-7.221 7.222z" />
      </g>
      <defs>
        <clipPath id="clip0_37_2075">
          <path fill="#fff" d="M0 0H24V24H0z" transform="translate(.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Vidget3;
