import React from 'react';

function SettingsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      fill="none"
      viewBox="0 0 17 16"
    >
      <g clipPath="url(#clip0_296_981)">
        <path
          fill="#CB1F1F"
          d="M15.871 10.033l-1.17-1a6.417 6.417 0 000-2.064l1.17-1a.572.572 0 00.166-.629l-.016-.046a7.923 7.923 0 00-1.423-2.463l-.032-.037a.574.574 0 00-.627-.17l-1.452.516a6.292 6.292 0 00-1.78-1.028l-.28-1.518a.573.573 0 00-.461-.46L9.918.127a8.067 8.067 0 00-2.84 0L7.03.135a.572.572 0 00-.46.459l-.283 1.525A6.283 6.283 0 004.52 3.144l-1.462-.52a.571.571 0 00-.627.17l-.032.037A7.965 7.965 0 00.975 5.294l-.016.046a.574.574 0 00.166.629l1.184 1.01a6.283 6.283 0 000 2.04l-1.184 1.01a.572.572 0 00-.166.629l.016.046c.323.9.802 1.73 1.423 2.463l.032.037a.574.574 0 00.627.17l1.462-.52a6.241 6.241 0 001.768 1.025l.282 1.525a.573.573 0 00.461.46l.048.008c.94.169 1.9.169 2.84 0l.048-.009a.572.572 0 00.46-.459l.281-1.518a6.25 6.25 0 001.78-1.028l1.452.516a.571.571 0 00.627-.17l.032-.037a7.963 7.963 0 001.423-2.463l.016-.046a.57.57 0 00-.166-.625zm-2.437-2.854a5.047 5.047 0 010 1.647l-.118.716 1.334 1.141a6.61 6.61 0 01-.76 1.314l-1.658-.587-.56.46c-.428.35-.902.626-1.417.818l-.68.256-.32 1.732a6.734 6.734 0 01-1.518 0l-.32-1.736-.674-.259a4.997 4.997 0 01-1.406-.816l-.56-.462-1.668.592a6.75 6.75 0 01-.761-1.314L3.696 9.53l-.116-.714a5.141 5.141 0 01-.066-.812c0-.274.022-.547.066-.813l.116-.714-1.348-1.152c.202-.466.457-.905.76-1.314l1.669.593.56-.463a4.996 4.996 0 011.406-.816l.676-.255.32-1.736a6.741 6.741 0 011.518 0l.32 1.732.68.255c.513.193.99.468 1.416.818l.56.461 1.658-.587c.303.408.557.85.76 1.314l-1.333 1.14.116.713zM8.5 4.681a3.143 3.143 0 100 6.286 3.143 3.143 0 000-6.286zm1.414 4.557a1.993 1.993 0 01-1.414.586A1.993 1.993 0 016.65 8.59a1.993 1.993 0 01.434-2.18c.38-.379.88-.586 1.415-.586.534 0 1.036.207 1.414.586a1.995 1.995 0 01.586 1.414c0 .534-.21 1.036-.586 1.414z"
        />
      </g>
      <defs>
        <clipPath id="clip0_296_981">
          <path fill="#fff" d="M0 0H16V16H0z" transform="translate(.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SettingsIcon;
