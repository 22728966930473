import styled from 'styled-components';

export const UserLogoStyles = styled.div`
  overflow: hidden;
  border-radius: 100%;
  
  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
`;
