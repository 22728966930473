import React, { useState } from 'react';
import { NewsFeedStyles } from './NewsFeedStyles';
import Pack1 from '../../assets/icons/main/stickerpacks/Pack1';
import Pack2 from '../../assets/icons/main/stickerpacks/Pack2';
import Pack3 from '../../assets/icons/main/stickerpacks/Pack3';
import Pack4 from '../../assets/icons/main/stickerpacks/Pack4';
import Pack5 from '../../assets/icons/main/stickerpacks/Pack5';
import Pack6 from '../../assets/icons/main/stickerpacks/Pack6';
import Pack7 from '../../assets/icons/main/stickerpacks/Pack7';
import Pack8 from '../../assets/icons/main/stickerpacks/Pack8';
import { changeUser } from '../../state/slices/userSlice';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { Api } from '../../api';
import { useWindowSize } from 'usehooks-ts';
import MainLayout from '../../components/layouts/MainLayout';
import Posts from '../../components/newsFeed/posts/Posts';

const stickerPacks = [
  <Pack1 />, <Pack2 />, <Pack3 />, <Pack4 />, <Pack5 />, <Pack6 />, <Pack7 />, <Pack8 />,
];

function NewsFeed() {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const reduxDispatch = useAppDispatch();
  const { data: user } = useAppSelector((state) => state.user);

  const windowSize = useWindowSize();

  const logout = () => {
    Api.logout().then();

    if (localStorage.getItem('chat_token')) {
      localStorage.removeItem('chat_token');
    }

    localStorage.removeItem('logged_in');
    localStorage.removeItem('access_token');
    localStorage.removeItem('access_token_expired_at');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('refresh_token_expired_at');
    reduxDispatch(changeUser(null));
  };

  return (
    <NewsFeedStyles>
      <MainLayout>
        <Posts />
      </MainLayout>
    </NewsFeedStyles>

  );
}

export default NewsFeed;
