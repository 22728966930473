export function mapFromFileToFormData(file: File, width?: number, height?: number) {
  const formData = new FormData();

  formData.append('file', file);

  if (width) {
    formData.append('width', width.toString());
  }

  if (height) {
    formData.append('height', height.toString());
  }

  return formData;
}
