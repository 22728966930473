import React from 'react';

function Pack6() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32"
    >
      <g fill="#000" clipPath="url(#clip0_37_2060)">
        <path d="M16.547 14.37a2.576 2.576 0 100-5.151 2.576 2.576 0 000 5.151z" />
        <path d="M16.5 0C7.663 0 .5 7.164.5 16s7.163 16 16 16c8.836 0 16-7.164 16-16s-7.164-16-16-16zm-.033 7.326l.033-.001h.034c2.696.043 4.889 2.271 4.889 4.968 0 3.297-3.523 6.635-4.923 7.832a20.48 20.48 0 01-.308-.27l-.005-.004c-1.55-1.377-4.61-4.485-4.61-7.558 0-2.697 2.194-4.925 4.89-4.967zm.103 17.448c-2.951 0-5.939-.847-5.939-2.464 0-1.135 1.471-1.89 3.368-2.24.33.332.67.654 1.018.965-2.065.231-3.279.885-3.279 1.274 0 .479 1.834 1.358 4.832 1.358 2.997 0 4.831-.88 4.831-1.358 0-.397-1.265-1.067-3.405-1.285.352-.315.694-.64 1.027-.975 1.953.34 3.485 1.102 3.485 2.26 0 1.618-2.988 2.465-5.938 2.465z" />
      </g>
      <defs>
        <clipPath id="clip0_37_2060">
          <path fill="#fff" d="M0 0H32V32H0z" transform="translate(.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Pack6;
