import React from 'react';
import { Link } from 'react-router-dom';
import LogoRow from '../../../assets/icons/LogoRow';
import { MenuMobileStyles } from './MenuMobileStyles';
import CrossIcon from '../../../assets/icons/main/CrossIcon';
import { navLinks } from '../../../constants/navLinks';

function MenuMobile({ open, setOpen }: {open: boolean, setOpen(): void}) {
  return (
    <MenuMobileStyles className={`menuMobile ${open ? 'open' : ''}`}>
      <div className="menuMobileHead">
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button type="button" onClick={setOpen}><CrossIcon /></button>

        <Link to="/main">
          <LogoRow />
        </Link>
      </div>

      <div className="navWrapper">
        <nav className="textBase18">
          {navLinks.map((link) => (
            <li key={link.path}>
              <Link to={link.path} onClick={setOpen}>
                {link.name}
              </Link>
            </li>
          ))}
        </nav>
      </div>
    </MenuMobileStyles>
  );
}

export default MenuMobile;
