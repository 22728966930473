import React from 'react';

function Pack4() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32"
    >
      <g clipPath="url(#clip0_37_2053)">
        <path
          fill="#000"
          d="M16.5 0C7.664 0 .5 7.164.5 16c0 8.837 7.164 16 16 16 8.837 0 16-7.163 16-16 0-8.836-7.163-16-16-16zm-6.455 22.53l-3.966 1.063 1.086-4.05a8.706 8.706 0 112.88 2.988zm16.884 2.748l-3.608-.966a7.883 7.883 0 01-4.227 1.224 7.884 7.884 0 01-3.12-.64c4.807-.63 8.532-4.75 8.532-9.726a9.759 9.759 0 00-.877-4.046 7.909 7.909 0 013.385 6.492 7.886 7.886 0 01-1.072 3.978l.987 3.684z"
        />
      </g>
      <defs>
        <clipPath id="clip0_37_2053">
          <path fill="#fff" d="M0 0H32V32H0z" transform="translate(.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Pack4;
