import React from 'react';
import MoonLoader from 'react-spinners/MoonLoader';
import { LoaderStyles } from './LoaderStyles';
import { RingLoader } from 'react-spinners';

interface LoaderProps {
  color?: string;
  size?: number;
}

function Loader({ color, size }: LoaderProps) {
  return (
    <LoaderStyles>
      <RingLoader
        color={color || '#A21919'}
        size={size || 100}
      />
    </LoaderStyles>
  );
}

export default Loader;
