import styled from 'styled-components';

export const MenuMobileStyles = styled.div`
  position: relative;
  z-index: 2;
  
  display: none;
  gap: 32px;
  
  padding: 12px;
  box-sizing: border-box;
  
  border-radius: 4px 4px 0px 0px;
  
  transition: 0.3s ease all;
  
  transform: translateX(-100%);
  
  &.open {
    transform: translateX(0);
  }
  
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  
  .menuMobileHead {
    display: flex;
    justify-content: space-between;
    width: 100%;

    a > svg {
      width: 200px;
      height: 54.417px;
      
      @media (max-width: 500px) {
        width: 113.458px;
        height: 30.87px;
      }
    }
  }
  
  .navWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    
    flex-grow: 1;
  }
  
  nav {
    display: grid;
    gap: 32px;
    
    a {
      color: #BF2B2B;
      
      transition: 0.3s ease all;
      
      &:hover {
        color: var(--red-4);
      }
    }
  }
`;
