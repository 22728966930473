import styled from 'styled-components';

export const PostStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  
  padding: 16px;
  box-sizing: border-box;
  
  border-radius: 4px;
  border: 1px solid var(--Red-2, #CB1F1F);
  background: var(--Cards, #171616);
  
  .postHead {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    align-items: center;
    
    .leftPart {
      display: flex;
      gap: 8px;
      
      .userNameAndDateWrapper {
        display: flex;
        flex-direction: column;
      }
    }
  }
  
  .postBody {
    display: flex;
    flex-direction: column;
    gap: 8px;
    
    .text {
      white-space: pre-wrap;
      
      .unfoldText {
        cursor: pointer;
        color: var(--red-2);
        
        transition: 0.3s ease color;
        
        &:hover {
          color: var(--red-3);
        }
      }
    }
    
    .imagesContainer {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
`;
