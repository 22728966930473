import React from 'react';
import { UserLogoStyles } from './UserLogoStyles';

interface UserLogoProps {
  logo: string | null,
  name: string,
  size?: number,
  color?: string,
}

function UserLogo({
  logo, name, size = 48, color,
}: UserLogoProps) {
  return (
    <UserLogoStyles style={{ width: size, height: size }}>
      {logo ? <img src={logo} alt={`Аватарка ${name}`} loading="lazy" /> : (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 37 32" fill="none">
          <path d="M18.4085 0.00269518C8.41437 -0.168052 0.173444 7.79559 0.00269696 17.7898C-0.0942873 23.4565 2.4314 28.5495 6.45283 31.9365C6.71578 31.7021 7.00368 31.4974 7.31135 31.3259L12.7117 28.3802C13.0596 28.1904 13.3499 27.9104 13.5521 27.5697C13.7544 27.229 13.8612 26.8401 13.8612 26.4439V24.231C13.8612 24.231 12.276 22.335 11.6715 19.7008C11.4157 19.537 11.205 19.3117 11.0588 19.0455C10.9125 18.7794 10.8353 18.4807 10.8342 18.177V15.7551C10.8342 15.2224 11.0712 14.7464 11.4393 14.4131V10.9121C11.4393 10.9121 10.7201 5.46387 18.0985 5.46387C25.4768 5.46387 24.7576 10.9121 24.7576 10.9121V14.4131C25.1264 14.7464 25.3627 15.2224 25.3627 15.7551V18.177C25.3627 18.9911 24.8163 19.6762 24.0746 19.905C23.6635 21.197 23.0573 22.4187 22.277 23.5275C22.0782 23.8082 21.8925 24.0459 21.7299 24.231V26.4999C21.7299 27.3352 22.2019 28.0995 22.949 28.4724L28.7319 31.3635C29.0725 31.5372 29.3912 31.7509 29.6813 32C33.5811 28.7476 36.1007 23.8847 36.1942 18.4085C36.3663 8.41437 28.4034 0.173442 18.4085 0.00269518Z" fill={color || '#A21919'} />
        </svg>
      )}
    </UserLogoStyles>
  );
}

export default UserLogo;
