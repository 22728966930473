import React from 'react';

function Pack7() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32"
    >
      <g fill="#000" clipPath="url(#clip0_37_2049)">
        <path d="M16.5 0C7.663 0 .5 7.164.5 16c0 8.835 7.163 16 16 16 8.836 0 16-7.164 16-16 0-8.837-7.164-16-16-16zm8.208 23.896a1.66 1.66 0 01-1.66 1.66H9.952a1.66 1.66 0 01-1.66-1.66V8.034c0-.916.743-1.66 1.66-1.66h13.095c.916 0 1.66.744 1.66 1.66v15.862z" />
        <path d="M20.048 17.227h-7.096a.756.756 0 00-.756.756v4.402a.755.755 0 00.756.757h7.096a.755.755 0 00.756-.757v-4.402a.756.756 0 00-.756-.756zm-10.505-5.18h1.43v1.66h-1.43v-1.66zm0-3.125h1.43v1.66h-1.43v-1.66zm0 12.496h1.43v1.66h-1.43v-1.66zm0-3.124h1.43v1.66h-1.43v-1.66zm0-3.124h1.43v1.66h-1.43v-1.66zM20.048 8.79h-7.096a.756.756 0 00-.756.756v4.403a.754.754 0 00.756.756h7.096a.757.757 0 00.756-.756V9.545a.757.757 0 00-.756-.756zm1.99.133h1.43v1.66h-1.43v-1.66zm0 12.496h1.43v1.66h-1.43v-1.66zm0-9.372h1.43v1.66h-1.43v-1.66zm0 6.248h1.43v1.66h-1.43v-1.66zm0-3.124h1.43v1.66h-1.43v-1.66z" />
      </g>
      <defs>
        <clipPath id="clip0_37_2049">
          <path fill="#fff" d="M0 0H32V32H0z" transform="translate(.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Pack7;
