import React, { Fragment, useState } from 'react';
import { MyPageStyles } from './MyPageStyles';
import { Link } from 'react-router-dom';
import AchievementsIcon from '../../assets/icons/main/AchievementsIcon';
import Pack1 from '../../assets/icons/main/stickerpacks/Pack1';
import Pack2 from '../../assets/icons/main/stickerpacks/Pack2';
import Pack3 from '../../assets/icons/main/stickerpacks/Pack3';
import Pack4 from '../../assets/icons/main/stickerpacks/Pack4';
import Pack5 from '../../assets/icons/main/stickerpacks/Pack5';
import Pack6 from '../../assets/icons/main/stickerpacks/Pack6';
import Pack7 from '../../assets/icons/main/stickerpacks/Pack7';
import Pack8 from '../../assets/icons/main/stickerpacks/Pack8';
import { changeUser } from '../../state/slices/userSlice';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { Api } from '../../api';
import { useWindowSize } from 'usehooks-ts';
import UserLogo from '../../components/other/userLogo/UserLogo';
import MainLayout from '../../components/layouts/MainLayout';
import Posts from '../../components/myPage/posts/Posts';

const stickerPacks = [
  <Pack1 />, <Pack2 />, <Pack3 />, <Pack4 />, <Pack5 />, <Pack6 />, <Pack7 />, <Pack8 />,
];

function MyPage() {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const reduxDispatch = useAppDispatch();
  const { data: user } = useAppSelector((state) => state.user);

  const windowSize = useWindowSize();

  const logout = () => {
    Api.logout().then();

    if (localStorage.getItem('chat_token')) {
      localStorage.removeItem('chat_token');
    }

    localStorage.removeItem('logged_in');
    localStorage.removeItem('access_token');
    localStorage.removeItem('access_token_expired_at');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('refresh_token_expired_at');
    reduxDispatch(changeUser(null));
  };

  return (
    <MainLayout>
      <MyPageStyles className="123">
        <div className="userMenu">
          <Link to="/rating">
            <span>Рейтинг</span>
            <span>1000</span>
            <span />
          </Link>
          <Link to="/profile">
            <UserLogo logo={user!.file_url ? user!.file_url : null} name={user!.login} size={112} />
          </Link>
          <Link to="/achievements">
            <span>Достижения</span>
            <AchievementsIcon />
            <span>В разработке</span>
          </Link>
        </div>

        <div className="stickerPacks">
          <div className="title textBase12">
            Стикерпаки в разработке
          </div>
          <div className="body">
            {stickerPacks.map((Icon, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={index}>{Icon}</Fragment>
            ))}
          </div>
        </div>

        <Posts />

      </MyPageStyles>
    </MainLayout>
  );
}

export default MyPage;
