import React from 'react';
import { RingLoader } from 'react-spinners';

function MainLoader({ size = 100 }: {size?: number}) {
  return (
    <RingLoader
      color="#A21919"
      size={100}
    />
  );
}

export default MainLoader;
