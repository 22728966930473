import React, { Fragment, useEffect, useState } from 'react';
import NewsPaper from '../../../assets/icons/main/NewsPaper';
import { TPost } from '../../../types/post.types';
import { PostsStyles } from './PostsStyles';
import CreatePost from '../../posts/create/CreatePost';
import workWithResponse from '../../../helpers/workWithResponse';
import { Api } from '../../../api';
import { notification } from '../../../helpers/notifications/toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import MainLoader from '../../other/loaders/MainLoader';
import Post from '../../posts/post/Post';
import { useWindowSize } from 'usehooks-ts';
import Vidget1 from '../../../assets/icons/main/widgets/Vidget1';
import Vidget2 from '../../../assets/icons/main/widgets/Vidget2';
import Vidget3 from '../../../assets/icons/main/widgets/Vidget3';
import { useAppSelector } from '../../../state/hooks';
import classNames from 'classnames';
import Adversting from '../../adversting/Adversting';

function generateText(count: number, singularText: string, plural1Text: string, plural2Text: string) {
  const stringifyCount = String(count);

  const countLastChar = +stringifyCount[stringifyCount.length - 1];

  if (countLastChar === 1) {
    return singularText;
  }

  if (countLastChar > 0 && countLastChar < 5) {
    return plural1Text;
  }

  return plural2Text;
}

function Posts() {
  const [posts, setPosts] = useState<TPost[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { width } = useWindowSize();

  const { data: user } = useAppSelector((state) => state.user);

  function handlePostCreate(post: TPost) {
    setPosts([post, ...posts]);
    setTotal((total) => total + 1);
  }

  function onPostDelete(postId: number) {
    setPosts(posts.filter((post) => postId !== post.id));
    setTotal((total) => total - 1);
  }

  async function getPosts() {
    try {
      setIsLoading(true);

      await workWithResponse(() => Api.getPostsByUserId(user!.id, posts.length, 10)).then((res) => {
        if (res.error) {
          notification.error('Произошла ошибка при загрузке новых постов');
        } else if (res.data) {
          setPosts([...posts, ...res.data.data]);

          if (total !== res.data.count) {
            setTotal(res.data.count);
          }
        }
      });
    } catch (e) {
      console.error(e);

      if (e instanceof Error && e.message) {
        notification.error(e.message);
      } else {
        notification.error('Произошла неизвестная ошибка');
      }
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getPosts();
  }, []);

  return (
    <div className="mainPageFooter">
      <div className="footerHeader textBase12">
        <span>
          {total}
          {' '}
          {generateText(total, 'запись', 'записи', 'записей')}
        </span>
      </div>

      <div className="footerBody">
        <div className="widgets">
          <div className="widgetsHeader textBase12">
            Виджеты в разработке
          </div>

          <div className="widgetsList">
            {[<Vidget1 />, <Vidget2 />, <Vidget3 />].map((Vidget, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="widget textBase12" key={index}>
                {Vidget}
                Виджет
              </div>
            ))}
          </div>
        </div>

        <PostsStyles>
          <div className={classNames('postsHeadWrapper', { empty: posts.length === 0 })}>
            <CreatePost onPostCreate={handlePostCreate} />

            {posts.length === 0 && (
              <div className="postsEmpty footerBodyPadding">
                <NewsPaper />

                <span>Лента новостей пока пустая</span>
              </div>
            )}
          </div>

          {/* <div */}
          {/*  id="scrollableDiv" */}
          {/*  style={{ */}
          {/*    overflow: 'auto', */}
          {/*    display: 'flex', */}
          {/*    flexDirection: 'column', */}
          {/*  }} */}
          {/* > */}
          {posts.length > 0 && (
            <InfiniteScroll
              dataLength={posts.length}
              next={() => {
                getPosts();
              }}
              style={{ display: 'flex', flexDirection: 'column' }} // To put endMessage and loader to the top.
              hasMore={total > posts.length}
              loader={<MainLoader size={16} />}
              scrollableTarget="scrollableDiv"/**/
            >
              {posts.map((post, index) => (
                <Fragment key={post.id}>
                  {/* {index !== 0 && index % 3 === 0 && width > 1000 && 'Reklama'} */}
                  {index === 3 && width > 1000 && <Adversting />}

                  <Post post={post} onPostDelete={onPostDelete} />
                </Fragment>
              ))}
            </InfiniteScroll>
          )}

        </PostsStyles>
      </div>
    </div>
  );
}

export default Posts;
