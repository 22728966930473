import React from 'react';

function Pack1() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32"
    >
      <path
        fill="#000"
        d="M16.5 25.366a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM19.987 7.28h-6.974c-.391 0-.709.303-.709.675v13.183c0 .373.318.675.709.675h6.974c.391 0 .71-.303.71-.675V7.955c-.001-.372-.319-.675-.71-.675z"
      />
      <path
        fill="#000"
        d="M16.5 0C7.663 0 .5 7.164.5 16c0 8.835 7.163 16 16 16 8.836 0 16-7.164 16-16 0-8.837-7.164-16-16-16zm6.224 23.896a2.213 2.213 0 01-2.213 2.213h-8.023a2.213 2.213 0 01-2.213-2.213V8.034c0-1.222.991-2.213 2.213-2.213h8.023c1.222 0 2.213.991 2.213 2.213v15.862z"
      />
    </svg>
  );
}

export default Pack1;
