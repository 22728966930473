import React, { Fragment, useEffect, useState } from 'react';
import Modal from '../../../modal/Modal';
import { notification } from '../../../../helpers/notifications/toastify';
import { PlusIcon } from '../../../../assets/icons/createPost/PlusIcon';
import Button from '../../../buttons/button/Button';
import { TPostFile } from '../../../../types/post.types';
import { EditIcon } from '../../../../assets/icons/createPost/EditIcon';
import { TrashIcon } from '../../../../assets/icons/createPost/TrashIcon';
import UserLogo from '../../../other/userLogo/UserLogo';
import { useAppSelector } from '../../../../state/hooks';
import { CreatePostModalNames } from '../CreatePost';
import MainLoader from '../../../other/loaders/MainLoader';
import workWithResponse from '../../../../helpers/workWithResponse';
import { Api } from '../../../../api';

function NotSavedImage({ imageFile, index, handleDelete }: {imageFile: File, index: number, handleDelete: (index: number) => void}) {
  const [image, setImage] = useState<null | string>(null);

  useEffect(() => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result as string);
    };
    reader.readAsDataURL(imageFile);
  }, []);

  return (
    <div className="imagePreview">
      <div className="imageButtons">
        <button type="button" onClick={() => handleDelete(index)}>
          {TrashIcon}
        </button>

        {/* <button type="button" onClick={handleUploadButtonClick}> */}
        {/*  {EditIcon} */}
        {/* </button> */}
      </div>
      {image && <img src={image} alt="" />}
    </div>
  );
}

interface AddFilesModalProps {
  uploadedFilesCount: number;

  onClose: (modalName: CreatePostModalNames, dontCloseWarning: boolean) => void;
  handleBack: () => void;
  handleSubmit: (uploadedFiles: TPostFile[]) => void;
}

const maxFilesCount = 1;
const maxFileSize = 1;

function AddFilesModal({
  uploadedFilesCount, onClose, handleBack, handleSubmit,
}: AddFilesModalProps) {
  const { data: user } = useAppSelector((state) => state.user);

  const [files, setFiles] = useState<File[]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  function handleFileInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const selectedFiles = e.target.files;

    if (selectedFiles) {
      let filesFilteredBySize = Array.from(selectedFiles).filter((file) => file.size <= maxFileSize * 1048576);

      if (filesFilteredBySize.length < selectedFiles.length) {
        notification.error(`Максимальный разрешимый размер файла ${maxFileSize}МБ. Файлы превышающие размером ${maxFileSize}МБ были удалены.`);
      }

      if (filesFilteredBySize && filesFilteredBySize.length + files.length + uploadedFilesCount > maxFilesCount) {
        const difference = Math.abs(maxFilesCount - filesFilteredBySize.length + files.length + uploadedFilesCount);

        notification.error(`Максимальное количество добавленых к посту файлов ${maxFilesCount}. Лишние файлы не были загружены.`);

        filesFilteredBySize = filesFilteredBySize.slice(0, filesFilteredBySize.length - difference);
      }

      if (filesFilteredBySize) {
        setFiles([...files, ...filesFilteredBySize]);
      }
    }
  }

  function handleUploadButtonClick() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.click();
  }

  function handleDelete(index: number) {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.value = '';

    setFiles(files.filter((file, i) => index !== i));
  }

  async function uploadFilesToServer() {
    setIsUploading(true);

    try {
      const promises = files.map((file) => workWithResponse(() => Api.createFile(file)));

      const responses = await Promise.all(promises);

      if (responses.every((response) => !response.error)) {
        const submitData: TPostFile[] = responses.map((res) => ({ url: res.data!.url, id: res.data!.id, mime_type: res.data!.mime_type }));

        handleSubmit(submitData);
      } else {
        notification.error('Произошла ошибка при сохранении мультимедиа на сервер');
      }
    } catch (error) {
      notification.error('Произошла ошибка при сохранении мультимедиа на сервер');
      console.error('Error', error);
    } finally {
      setIsUploading(false);
    }
  }

  return (
    <Modal onClose={() => onClose('addFiles', files.length > 0)} className="filesModal postModal">
      <input
        id="fileInput"
        type="file"
        // accept=".pdf,.doc,.docx,.txt,.jpg,.jpeg,.png,.gif,.mp4,.avi,.mp3,.wav"
        accept="image/*"
        onChange={handleFileInputChange}
        style={{ display: 'none' }}
      />

      <div className="head">
        {files.length > 0 ? (
          <div className="titleWrapper">
            <span className="title textBase22 fontSemiBold">Загрузка файлов</span>
          </div>
        ) : (
          <div className="userContainer">
            <UserLogo size={48} name={user!.login} logo={user && user.file_url ? user.file_url : null} />

            <span className="textBase18">{user!.login}</span>
          </div>
        )}

        {isUploading ? (
          <div className="content">
            <div className="loaderContainer">
              <MainLoader size={100} />
            </div>
          </div>
        ) : (
          <>
            {files.length === 0 && (
              <div className="content notAddedFilesContent">
                <div className="textContainer">
                  <span className="title textBase22 fontSemiBold">Выберите файл</span>

                  <span>Поделитесь изображением в своей публикации</span>
                </div>

                <Button theme="red" onClick={handleUploadButtonClick} text="Загрузить с компьютера" />
              </div>
            )}

            {files.length > 0 && (
              <div className="content">
                {files.map((file, index) => {
                  if (file.type.startsWith('image')) {
                    // eslint-disable-next-line react/no-array-index-key
                    return <Fragment key={index}><NotSavedImage imageFile={file} index={index} handleDelete={handleDelete} /></Fragment>;
                  }

                  return null;
                })}
              </div>
            )}
          </>
        )}
      </div>

      <div className="footer">
        <div />
        {/* <button type="button" className="addFilesButton" onClick={handleUploadButtonClick}> */}
        {/*  {PlusIcon} */}
        {/* </button> */}

        <div className="buttonsWrapper">
          <Button theme="black" isLoading={isUploading} onClick={handleBack} text="Назад" />
          <Button theme="red" isLoading={isUploading} onClick={uploadFilesToServer} text="Добавить" disabled={files.length === 0} />
        </div>
      </div>
    </Modal>
  );
}

export default AddFilesModal;
