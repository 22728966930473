import React, { useRef } from 'react';
import { DropDownStyles } from './DropDownStyles';
import { useClickOutside } from '../../../hooks/useClickOutside';

type DropDownProps = {
  isOpened: boolean;
  onClose: () => void;
  children: any;
  exclude?: any;
  className?: string;
}

function DropDown({
  isOpened, onClose, children, exclude, className,
}: DropDownProps) {
  const ref = useRef(null);

  // const handleClickOutside = () => {
  //   // Your custom logic here
  //   onClose();
  // }
  //
  // useOnClickOutside(ref, handleClickOutside)

  useClickOutside(ref, onClose, exclude);

  if (!isOpened) {
    return null;
  }

  return (
    <DropDownStyles ref={ref} className={`userMenu textSemiBold12 ${className}`}>
      {children}
    </DropDownStyles>
  );
}

export default DropDown;
