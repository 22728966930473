import styled from 'styled-components';

export const MainLayoutStyles = styled.div<{headHeight?: number}>`
  min-height: 100vh;
  
  padding-top: 40px;
  padding-bottom: 2px;
  
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: min-content min-content 1fr 1fr;
  gap: 20px;
  
  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content auto auto;
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    grid-template-rows: min-content auto auto auto;
  }
  
  .head {
    grid-column: 1/13;
    grid-row: 1/2;
    
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 20px;

    @media (max-width: 1000px) {
      grid-column: 1/3;
      grid-row: 1/2;
    }

    @media (max-width: 700px) {
      grid-template-columns: 1fr min-content;
    }

    @media (max-width: 500px) {
      grid-column: 1/2;
    }
    
    .logoContainer {
      grid-column: 1/4;
      display: flex;
      align-items: center;
      gap: 12px;

      @media (max-width: 700px) {
        grid-column: 1/2;
        grid-row: 1/2;
      }
    
      @media (min-width: 1001px) {
        .menuButton {
          display: none;
        }
      }
    }

    .logo {
      width: min-content;
      height: min-content;
      
      svg {
        @media (max-width: 1000px) {
          width: 113.458px;
          height: 30.87px;
        }
      }
    }
    
    .balanceDesktop {
      display: none;
      
      @media (min-width: 1001px) {
        display: block;
      }
    }

    .balanceTablet {
      display: block;

      @media (min-width: 1001px) {
        display: none;
      }
    }
    
    .userInfoContainer {
      display: flex;
      flex-direction: column;
      gap: 12px;
      grid-column: 4/10;

      @media (max-width: 700px) {
        grid-column: 1/3;
        grid-row: 2/3;
      }
    }
    
    .userInfo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2px;
      flex-grow: 1;

      padding: 16px;
      width: 100%;
      box-sizing: border-box;

      background: var(--red-2, #CB1F1F);
      color: #FFF;
      text-align: center;
      font-family: Gilroy-Bold, sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 42px;
      letter-spacing: 0.56px;
    }

    
    
    .buttonsWrapper {
      grid-column: 10/13;
      
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: 700px) {
        grid-column: 2/3;
      }
    }

    .buttonsContainer {
      display: flex;
      gap: 17px;

      margin-bottom: 22px;
      
      @media (max-width: 1000px) {
        flex-direction: column;
      }
      
      @media (max-width: 700px) {
        flex-direction: row;

        margin-bottom: 0;
        
        span {
          display: none;
        }
      }

      .layoutButton {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        width: 100%;
        padding: 12px;

        background: none;
        color: var(--red-2, #CB1F1F);
        font-family: Gilroy-SemiBold, sans-serif;
        border-radius: 4px;
        border: 1px solid var(--red-2, #CB1F1F);
        background: var(--background, #0F0E0E);
        box-shadow: 0px 2px 3px 1px rgba(213, 76, 76, 0.30), 0px 0px 2px 0px rgba(213, 76, 76, 0.40);
        box-sizing: border-box;

        transition: 0.3s ease box-shadow;

        &:hover {
          box-shadow: 0px 2px 6px 2px rgba(213, 76, 76, 0.50), 0px 0px 4px 1px rgba(213, 76, 76, 0.60);
        }
      }
    }

    .balance {
      padding: 12px;

      border-radius: 4px;
      background: var(--red-2, #CB1F1F);
      color: var(--grey-1, #FDFDFD);
      text-align: center;
      box-shadow: 0px 2px 3px 1px rgba(213, 76, 76, 0.30), 0px 0px 2px 0px rgba(213, 76, 76, 0.40);
    }
  }

  .navContainer {
    display: none;
    
    @media (min-width: 1001px) {
      display: block;
    }
    
    grid-column: 1/4;
    grid-row: 2/3;
  }

  .adverstingContainer1, .adverstingContainer2 {
    display: none;
  }
  
  .adverstingContainer1 {
    @media (min-width: 1001px) {
      grid-column: 1/4;
      grid-row: 3/5;

      display: flex;
      flex-direction: column;
    }

    .advertising {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 40px;

      flex-grow: 1;

      padding: 22px;

      border-radius: 0px 0px 4px 4px;
      border: 1px solid var(--red-2, #CB1F1F);
      background: var(--cards, #171616);

      span {
        color: var(--red-2, #CB1F1F);
        text-align: center;
        font-family: Gilroy-SemiBold, sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px;
      }
    }
  }
  
  .adverstingContainer2 {
    @media (min-width: 1001px) {
      grid-column: 10/13;
      grid-row: 2/5;

      display: flex;
      flex-direction: column;
    }


    .advertising {
      display: flex;
      flex-direction: column;
      gap: 33px;
      flex-grow: 1;
      
      .advertisingItem {
        img {
          width: 100%;
          height: 100%;
        }
      }

      .emptyAdvertisingItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;
        flex-grow: 1;

        text-align: center;
        padding: 16px;
        color: var(--red-2, #CB1F1F);
        border-radius: 4px;
        background: var(--cards, #171616);
      }
    }
  }

  .center {
    grid-column: 4/10;
    grid-row: 2/5;

    display: flex;
    flex-direction: column;
    
    max-height: 100vh;
    
    @media (min-width: 1001px) {
      //max-height: calc(100vh - ${({ headHeight }) => (headHeight ? headHeight + 42 + 20 : 0)}px);
    }

    @media (max-width: 1000px) {
      grid-column: 1/3;
      grid-row: 2/3;
    }

    @media (max-width: 500px) {
      grid-column: 1/2;
    }
  }
  
  .menuMobile {
    position: fixed;
    width: 100vw;
    min-height: 100vh;
    
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    top: 0;
    left: 0;
    background: #0F0E0E;
    
    .navContainer {
      display: flex;
      border: none;
    }
  }
`;
