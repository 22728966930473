import React from 'react';

function Pack8() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32"
    >
      <g clipPath="url(#clip0_37_2039)">
        <path
          fill="#000"
          d="M16.5 0C7.663 0 .5 7.164.5 16s7.163 16 16 16c8.836 0 16-7.164 16-16s-7.164-16-16-16zm0 5.62l9.468 5.904H7.032L16.5 5.62zm9.586 15.332c0 .947-.768 1.714-1.715 1.714H8.629a1.714 1.714 0 01-1.714-1.714V11.9c0-.1.01-.198.027-.294l9.046 5.641c.011.007.023.012.034.018a.948.948 0 00.233.096l.021.005a.977.977 0 00.224.028h.001c.075 0 .15-.01.224-.028l.02-.005a.948.948 0 00.234-.096c.011-.006.023-.01.034-.018l9.046-5.64c.017.095.027.192.027.293v9.052z"
        />
      </g>
      <defs>
        <clipPath id="clip0_37_2039">
          <path fill="#fff" d="M0 0H32V32H0z" transform="translate(.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Pack8;
