import React from 'react';

import adversting1 from '../../assets/images/main/rutor_lux2.gif';
import { AdverstingStyles } from './AdverstingStyles';

function Adversting() {
  return (
    <AdverstingStyles href="http://rutorsite3s7oalfxlcv5kdk6opadvkoremcoyrdm75rgips6pv33did.onion/" target="_blank">
      <img src={adversting1} alt="" loading="lazy" />
    </AdverstingStyles>
  );
}

export default Adversting;
