import ApiResponse from '../api/entities/ApiResponse';

async function workWithResponse<T>(request: () => Promise<ApiResponse<T>>): Promise<{ data: T | null, error: string | null, statusCode: number }> {
  const response = await request();

  if (response.statusCode >= 200 && response.statusCode < 300) {
    return { data: response.data, error: null, statusCode: response.statusCode };
  }
  const errorMessage = (response.data as {message: string})?.message || 'Unknown error occurred';
  return { data: null, error: errorMessage, statusCode: response.statusCode };
}

export default workWithResponse;
