import styled from 'styled-components';

export const AdverstingStyles = styled.a`
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 16px;
  box-sizing: border-box;

  border-radius: 4px;
  border: 1px solid var(--Red-2, #CB1F1F);
  background: var(--Cards, #171616);
  
  img {
    width: 100%;
  }
`;
